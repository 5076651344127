import React from 'react';
import { NdovuLogo } from '../../Common/NdovuLogo';

const RiskProfileImage = ({ image }) => {
  return (
    <div className="text-center mb-5">
      <NdovuLogo image={image} />
    </div>
  );
};

export default RiskProfileImage;
