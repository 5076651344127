import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { motion } from 'framer-motion/dist/framer-motion';

import { actions as pocketActions } from '../_redux/reducers';

import { actions as goalReducerActions } from '../../Goal/_redux/goalReducers';
import { actions as planReducerActions } from '../../Plan/_redux/planReducers';

import { eventTracking } from '../../../eventTracking/httpRequest';
import {
  customerIOEventType,
  investmentType,
  paymentMethod,
  pocketActionsEnum,
} from '../../../helpers/constants';
import convertAmountToNumber from '../../../helpers/convertAmountToNumber';
import { findCurrency } from '../../../helpers/findCurreny';
import { getConversion } from '../../../helpers/getConversion';
import {
  addCommas,
  hasOnlyOneDot,
  removeNonNumeric,
} from '../../../helpers/handleInput';
import HorizontalLine from '../../../helpers/horizontalLine';
import Alert from '../../Common/Alert';
import Back from '../../Common/BackArrow';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import { CommonSuccessModal } from '../../Common/CommonSuccessModal';
import CurrenciesModal from '../../Common/CurrenciesModal';
import Dropdown from '../../Common/Dropdown';
import ErrorModal from '../../Common/ErrorModal';
import Input from '../../Common/Input/ControlledInput';
import { Note } from '../../Common/Note';
import { PaymentMethodModalContent } from '../../Common/PaymentMethodModalContent';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import ReusableModal from '../../Common/ReusableModal/index';
import { VerticalLine } from '../../Common/VerticalLine';
import { amountValidation } from '../../Invest/_redux/investmentAction';
import {
  getSettlementDetails,
  investInFundFromPocket,
  investInGoalFromPocket,
  transferFromPocketToPocket,
} from '../_redux/axiosRequest';
import MainWrapper from '../components/MainWrapper';
import { PaymentDetailsItem } from '../components/PaymentDetailsItem';
import { pocketContents } from '../copies';
import { getInitialInputValue } from '../helpers/InitialInputValue';
import { pocketBalanceIsLessThanMinimumWithdrawableAmount } from '../helpers/amountHelper';
import { displayWarningOnWithdrawal } from '../helpers/displayWarningOnWithdraw';
import {
  minimumAndMaximumAmountValidation,
  validatePlanInvestment,
} from '../helpers/minimumAndMaximumValidation';
import { AnimatedPageContainer } from '../../Common/AnimatedPageContainer';

const PocketOperations = () => {
  const history = useHistory();
  const { pocketId } = useParams();
  const { id: planOrPlanSubscriptionOrGoalIdParam } = useParams();
  const { type } = useSelector(state => state.pocketFlow);

  const [openDropdown, setOpenDropdown] = useState({
    [pocketContents()?.[type?.path]?.inputName]: false,
    [pocketContents()?.[type?.path]?.inputName2]: false,
  });

  const pocketFlow = useSelector(state => state.pocketFlow);
  const [selectedOption, setSelectedOption] = useState({});
  const [paymentDetails, setPaymentDetails] = useState({});
  const [openPaymentMethodModal, setOpenPaymentMethodModal] = useState(false);
  const [openCongratulationsModal, setOpenCongratulationsModal] = useState(
    false,
  );
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [activePaymentMethod, setActivePaymentMethod] = useState(false);
  const [loading, setLoading] = useState(false);
  const alertInitialState = {
    alertMessage: null,
    alertMessageType: null,
  };
  const [alert, setAlert] = useState(alertInitialState);
  const [popupError, setPopupError] = useState(alertInitialState);

  const [settlementDetails, setSettlementDetails] = useState();
  const [warningMessage, setWarningMessage] = useState();
  const [disableAmountInput, setDisableAmountInput] = useState(false);

  const pockets = useSelector(state => state.pockets);
  const pocketDetails = useSelector(state => state.pocketDetails);
  const { currencies } = useSelector(state => state.currencies);
  const { conversions } = useSelector(state => state.conversions);
  const reduxViewedPlan = useSelector(state => state?.plan?.viewedPlan);
  const { investor } = useSelector(state => state.profile);

  const reduxGoalInvestmentDtls = useSelector(
    state => state?.goal?.goalInvestmentDetails,
  );

  const planDetails = useSelector(state => state?.plan?.viewedPlan);
  const pocketTopupDetails = useSelector(state => state.pocketTopupDetails);

  const roundAmountToTwoDecimalNumbers = false;

  const handleErrorMessage = (errorMessage, insideModal) => {
    insideModal
      ? setPopupError({ alertMessage: errorMessage, alertMessageType: 'error' })
      : setAlert({ alertMessage: errorMessage, alertMessageType: 'error' });
    insideModal && setOpenErrorModal(true);
    setOpenPaymentMethodModal(false);
    setLoading(false);
  };

  const dispatch = useDispatch();
  dispatch(pocketActions?.updatePockets(pockets));

  const fetchSettlementDetails = async () => {
    try {
      const res = await getSettlementDetails();
      if (res.status === 200) {
        setSettlementDetails(res.data.data);
      }
      setLoading(false);
    } catch (error) {}
  };

  const { investment, goal, withdraw, conversion } = pocketContents();

  const handleValidation = (
    validationFunctionArguments,
    keepRunningTheCode,
  ) => {
    const returnedErrorMessage =
      type?.path === goal
        ? minimumAndMaximumAmountValidation(validationFunctionArguments)
        : validatePlanInvestment(validationFunctionArguments, planDetails);

    if (returnedErrorMessage) {
      setAlert({
        alertMessage: returnedErrorMessage,
        alertMessageType: 'error',
      });
    }

    closeDropdown();
    // we should not stop running the code inside the function which handle onChange on input
    return returnedErrorMessage && !keepRunningTheCode ? true : false;
  };

  useEffect(() => {
    const { inputName, inputName2 } = pocketContents()?.[type?.path];

    const currentCurrency = pocketDetails?.currency
      ? pocketDetails?.currency
      : investor?.currency?.currency && investor?.currency?.currency;

    const currency = findCurrency(currencies, currentCurrency);
    const currentPocket = currency?.pockets;
    const currentPocketBalance = pocketDetails?.balance
      ? pocketDetails?.balance
      : currentPocket?.[0]?.balance;

    const initialInputValueFunctionArguments = {
      inputOneAmount: pocketFlow?.inputOne?.amount,
      inputTwoAmount: pocketFlow?.inputOne?.amount,
      currency,
      operationType: type?.path,
      currentPocketBalance,
    };

    const { inputOneInitialAmount } = getInitialInputValue(
      initialInputValueFunctionArguments,
    );

    const { inputTwoInitialAmount } = getInitialInputValue(
      initialInputValueFunctionArguments,
    );

    const pocketIsAvailable = currentPocket?.length > 0;

    const validationFunctionArguments = {
      amount: inputOneInitialAmount,
      currency: currency,
      operation: type?.path,
      pocketBalance: currentPocketBalance,
    };

    let errorMessage;
    if (type?.path === pocketActionsEnum.WITHDRAW) {
      errorMessage = displayWarningOnWithdrawal(
        currentPocketBalance,
        currency,
        type?.path,
        setWarningMessage,
        setDisableAmountInput,
        alertInitialState,
      );
    }

    if (!errorMessage) {
      handleValidation(validationFunctionArguments, true);
    }

    const amountIsLessThanMinimumWithdrawableAmount = pocketBalanceIsLessThanMinimumWithdrawableAmount(
      currentPocketBalance,
      currency?.minimumWithdrawableAmount,
      type?.path,
    );

    setPaymentDetails({
      [inputName]: {
        amount: amountIsLessThanMinimumWithdrawableAmount
          ? addCommas(inputOneInitialAmount, roundAmountToTwoDecimalNumbers)
          : addCommas(inputOneInitialAmount)
          ? addCommas(inputOneInitialAmount)
          : '0',
      },
      [inputName2]: {
        amount: addCommas(inputTwoInitialAmount)
          ? addCommas(inputTwoInitialAmount)
          : '0',
      },
      rate: 1,
    });

    setSelectedOption({
      ...selectedOption,
      [inputName]: {
        ...selectedOption[inputName],
        currency: currency,
      },

      [inputName2]: {
        ...selectedOption[inputName2],
        currency: currency,
      },
    });

    pocketIsAvailable &&
      type?.path === pocketActionsEnum.TOPUP &&
      dispatch(
        pocketActions.updatePocketTopupDetails({
          ...pocketTopupDetails,
          pocketCurrency: currency?.currency,
        }),
      );

    !pocketDetails &&
      pocketIsAvailable &&
      dispatch(
        pocketActions.updatePocket({
          ...currentPocket[0],
          currency: currency?.currency,
        }),
      );
    fetchSettlementDetails();
  }, [pockets, pocketId, pocketFlow, currencies]);

  const handleDropdown = dropdownName => {
    setOpenDropdown({
      ...openDropdown,
      [dropdownName]: !openDropdown[dropdownName],
    });
  };

  const closeDropdown = () => {
    const { inputName, inputName2 } = pocketContents()?.[type?.path];
    setOpenDropdown({
      [inputName]: false,
      [inputName2]: false,
    });
  };

  const handleConversion = async (convertFrom, convertTo) => {
    const conversion = await getConversion(convertFrom, convertTo, conversions);

    if (conversion?.error) {
      setAlert({
        alertMessage: conversion?.error,
        alertMessageType: 'error',
      });
      return;
    }

    return conversion?.offeredConversionRate;
  };

  const handleSelectOption = async (option, name) => {
    const { currency } = option;
    const { inputName, inputName2 } = pocketContents()?.[type?.path];
    const isConvertFromDropdown = name === inputName;

    isConvertFromDropdown && setAlert(alertInitialState);

    const inputOneAmount = paymentDetails[inputName]?.amount;
    const currentPocket = option?.pockets;

    const selectedCurrency = findCurrency(currencies, currency);

    const withdrawing = type?.path === pocketActionsEnum.WITHDRAW;

    const isPocketAvailable = currentPocket?.length;
    const pocketBalance = withdrawing && option.balance;

    const errorMessage = displayWarningOnWithdrawal(
      pocketBalance,
      selectedCurrency,
      type?.path,
      setWarningMessage,
      setDisableAmountInput,
      alertInitialState,
    );

    const validationFunctionArguments = {
      amount: inputOneAmount,
      currency: isConvertFromDropdown
        ? selectedCurrency
        : selectedOption[inputName]?.currency,
      operation: type?.path,
      pocketBalance,
    };

    if (type?.path === pocketActionsEnum.CONVERSION) {
      const convertFromCurrency =
        name === inputName
          ? currency
          : selectedOption[inputName]?.currency?.currency;
      const convertToCurrency =
        name === inputName2
          ? currency
          : selectedOption[inputName2]?.currency?.currency;

      const conversionRate = await handleConversion(
        convertFromCurrency,
        convertToCurrency,
      );

      const convertedAmount = addCommas(
        convertAmountToNumber(inputOneAmount) * conversionRate,
      );

      setPaymentDetails({
        ...paymentDetails,
        [inputName2]: {
          ...paymentDetails[inputName2],
          amount: convertedAmount ? convertedAmount : '0',
        },
        rate: conversionRate,
      });
    }

    if (
      type?.path === pocketActionsEnum.WITHDRAW &&
      convertAmountToNumber(pocketBalance) <
        selectedCurrency?.minimumWithdrawableAmount
    ) {
      setPaymentDetails({
        ...paymentDetails,
        [inputName]: {
          ...paymentDetails[inputName],
          amount: addCommas(pocketBalance),
        },
      });
    }

    setSelectedOption({
      ...selectedOption,
      [name]: {
        ...selectedOption[name],
        currency: option,
      },
    });

    if (name === inputName) {
      isPocketAvailable
        ? dispatch(
            pocketActions.updatePocket({
              ...currentPocket[0],
              currency: option?.currency,
            }),
          )
        : dispatch(pocketActions.updatePocket(option));
    }

    if (!errorMessage) {
      const errorFound = handleValidation(validationFunctionArguments, false);
      if (errorFound) return;
    }

    isConvertFromDropdown && setAlert(alertInitialState);
    closeDropdown();
  };

  const handleChange = async event => {
    if (disableAmountInput) return;
    const { value, name } = event.target;

    const hasOneDot = hasOnlyOneDot(value);
    if (!hasOneDot) return false;

    const numericalValue = removeNonNumeric(value);

    !isNaN(numericalValue) && setAlert(alertInitialState);

    const convertFromCurrency =
      selectedOption[pocketContents()?.[type?.path]?.inputName]?.currency
        ?.currency;
    const convertToCurrency =
      selectedOption[pocketContents()?.[type?.path]?.inputName2]?.currency
        ?.currency;

    const currency = findCurrency(currencies, convertFromCurrency);

    const validationFunctionArguments = {
      amount: numericalValue,
      currency,
      operation: type?.path,
      pocketBalance: pocketDetails?.balance,
    };

    const errorFound = handleValidation(validationFunctionArguments, true);
    if (errorFound) return;

    if (
      name === pocketContents()?.[type.path]?.inputName &&
      type?.path === conversion?.action
    ) {
      const conversionRate = await handleConversion(
        convertFromCurrency,
        convertToCurrency,
      );

      setPaymentDetails({
        ...paymentDetails,
        [name]: {
          ...paymentDetails[name],
          amount: addCommas(numericalValue),
        },
        [pocketContents()[type?.path]?.inputName2]: {
          ...paymentDetails[pocketContents()?.[type?.path]?.inputName2],
          amount: addCommas(
            convertAmountToNumber(numericalValue) * conversionRate,
          ),
        },
      });
    } else {
      setPaymentDetails({
        ...paymentDetails,
        [name]: {
          ...paymentDetails[name],
          amount: addCommas(numericalValue),
        },
      });
    }
  };

  const updatePlanInvestmentDetails = () => {
    dispatch(
      planReducerActions.updatePlanInvestmentDetails({
        amount: convertAmountToNumber(paymentDetails?.inputOne?.amount),
        currency: selectedOption?.inputOne?.currency?.currency,
        planId: planOrPlanSubscriptionOrGoalIdParam,
      }),
    );
  };

  const updatePocketTopupDetails = () => {
    dispatch(
      pocketActions.updatePocketTopupDetails({
        ...pocketTopupDetails,
        amount: convertAmountToNumber(paymentDetails?.inputOne?.amount),
        currency: selectedOption?.inputOne?.currency?.currency,
      }),
    );
  };

  const handlePaymentFromModal = async action => {
    setLoading(true);
    setPopupError(alertInitialState);
    setAlert(alertInitialState);

    const handleSuccessResponseFromModal = () => {
      setLoading(false);
      setOpenPaymentMethodModal(false);
      setOpenCongratulationsModal(true);
    };

    if (activePaymentMethod === paymentMethod.POCKET) {
      const termsAndConditions = JSON?.parse(
        localStorage?.getItem('termsAndConditions'),
      );
      const payload = {
        amount: convertAmountToNumber(paymentDetails?.inputOne?.amount),
        id: Number(planOrPlanSubscriptionOrGoalIdParam),
        type: pocketFlow?.investmentType,
        pocketId: Number(pocketDetails.id),
      };

      if (planDetails?.termsAndConditionsRequired && !planDetails?.isInvested) {
        payload.termsAndConditionsAccepted =
          termsAndConditions?.termsAndConditionsAccepted;
      }

      try {
        if (action === investment.action) {
          await investInFundFromPocket(payload);
          try {
            await eventTracking(customerIOEventType.PAY_NOW);
          } catch (error) {}
        } else {
          await investInGoalFromPocket(payload);
        }
        handleSuccessResponseFromModal();
      } catch (error) {
        handleErrorMessage(error?.response?.data?.message, true);
      }
    } else {
      updatePlanInvestmentDetails();

      history.push('/new-investment/plan-investment-payment', {
        backUrl: `/pocket/${pocketDetails?.id}/${type?.path}`,
      });
    }
  };

  const handleSubmit = async action => {
    setAlert(alertInitialState);
    setPopupError(alertInitialState);
    const { inputOne, inputTwo } = selectedOption;

    const pocketBalance = pocketDetails?.balance;

    const investingAmount = paymentDetails?.inputOne?.amount;
    const amount = convertAmountToNumber(investingAmount);
    const amountIsLessThanPocketBalance = Number(pocketBalance) < amount;
    const isConversion = action === conversion.action;

    const selectedCurrency = selectedOption?.inputOne?.currency;

    const updatePocketFlow = inputOneAmount => {
      dispatch(
        pocketActions.updatePocketFlow({
          inputOne: { amount: inputOneAmount },
          inputTwo: { amount: paymentDetails?.inputTwo?.amount },
        }),
      );
    };

    if (action === investment.action) {
      setLoading(true);
      try {
        await amountValidation(
          amount,
          selectedCurrency?.currency,
          Number(reduxViewedPlan?.id),
        );
        updatePocketFlow(investingAmount);
        setLoading(false);
        setOpenPaymentMethodModal(true);
      } catch (error) {
        setLoading(false);
        setAlert({
          alertMessage: error.response.data.message,
          alertMessageType: 'error',
        });
      }
    } else if (action === withdraw.action) {
      updatePocketFlow(
        amountIsLessThanPocketBalance ? pocketBalance : investingAmount,
      );
      localStorage.setItem('previousPage', history.location.pathname);

      if (settlementDetails.length > 0) {
        history.push('/settlement/accounts');
      } else {
        history.push('/settlement/notification');
      }
    } else if (isConversion) {
      setLoading(true);

      if (amount > pocketBalance) {
        handleErrorMessage(
          'You can not convert an amount which is greater than your current pocket balance',
          true,
        );
        return;
      }

      // Same currency conversion validation
      if (inputOne?.currency?.currency === inputTwo?.currency?.currency) {
        handleErrorMessage(
          'Origin and new pocket currencies can not be the same',
          true,
        );
        return;
      }

      try {
        const response = await transferFromPocketToPocket(
          {
            amount,
            newCurrency: selectedOption?.inputTwo?.currency?.currency,
          },
          Number(pocketDetails?.id),
        );

        if (response?.data?.success) {
          setOpenCongratulationsModal(true);
        }

        setLoading(false);
      } catch (error) {
        handleErrorMessage(error?.response?.data?.message, true);
      }
    } else if (action === goal.action) {
      dispatch(
        goalReducerActions.updateGoalInvestmentDetails({
          ...reduxGoalInvestmentDtls,
          amount,
          goalId: planOrPlanSubscriptionOrGoalIdParam,
          currency: selectedCurrency?.currency,
        }),
      );

      updatePocketFlow(investingAmount);
      setOpenPaymentMethodModal(true);
    } else if (action === pocketContents()?.topup.action) {
      updatePocketTopupDetails();

      history.push('/new-investment/plan-investment-payment', {
        backUrl: `/pocket/${pocketDetails?.id}/${type?.path}`,
      });
    } else {
      history.goBack();
    }
  };

  const modalTitle =
    type?.path === pocketActionsEnum.INVESTMENT &&
    pocketFlow?.investmentType === investmentType.TOP_UP
      ? pocketContents()?.[type?.path]?.fundTopupTitle
      : pocketContents()?.[type?.path]?.title;

  const transactionDetails = (
    <div className="payment-method-container mx-auto">
      <MainWrapper>
        <div className="px-md-10">
          <div className="text-center">
            <h3 className="mb-8">Confirm Transaction Details</h3>
            <HorizontalLine color="#D0DFE6" width={'100%'} height={0.1} />
            {popupError?.alertMessage ? <Alert alert={popupError} /> : null}
          </div>
          <div>
            <div className="d-flex justify-content-between confirm-trans-details-text">
              <div className="">Withdraw Amount</div>
              <div>
                <span className="text-blue font-weight-bold">
                  {pocketDetails?.currency}
                </span>
                <span className="font-weight-bold">
                  {' '}
                  {paymentDetails?.inputOne?.amount}
                </span>
              </div>
            </div>
            <HorizontalLine color="#D0DFE6" width={'100%'} height={0.1} />
          </div>

          <div className="d-flex justify-content-between confirm-trans-details-text">
            <div className="">Transaction Fee</div>
            <div>
              <span className="text-blue font-weight-bold">
                {pocketDetails?.currency}
              </span>
              <span className="font-weight-bold"> 0</span>
            </div>
          </div>
          <HorizontalLine color="#D0DFE6" width={'100%'} height={0.1} />
          <div className="d-flex justify-content-between confirm-trans-details-text">
            <div className="">Payable Amount</div>
            <div>
              <span className="text-blue font-weight-bold">
                {pocketDetails?.currency}
              </span>
              <span className="font-weight-bold">
                {' '}
                {paymentDetails?.inputOne?.amount}
              </span>
            </div>
          </div>
          <HorizontalLine color="#D0DFE6" width={'100%'} height={0.1} />
          <Note
            classes={
              'mx-auto my-4 bg-orange-200 border-orange-600 p-3 border-radius-7 text-13'
            }
            title={
              'Note: The transfer of your money to your account may take up to 2 business days. Standard bank and mobile money charges may apply'
            }
            titleColor="text-red-200"
            contentColor="text-red-200"
            alignText="text-left"
          />

          <ButtonsContainer>
            <CancelButton
              buttonText="Cancel"
              handleOnClick={() => {
                setAlert(alertInitialState);
                setOpenPaymentMethodModal(false);
              }}
              twinButton={true}
            />

            <ConfirmButton
              buttonText={'Confirm'}
              buttonType={'submit'}
              loading={loading}
              handleOnClick={() =>
                handlePaymentFromModal(
                  pocketContents()?.[type?.path.toLowerCase()].action,
                )
              }
              disable={loading}
              twinButton={true}
            />
          </ButtonsContainer>
        </div>
      </MainWrapper>
    </div>
  );

  const clearState = () => {
    setPaymentDetails(null);
    dispatch(pocketActions.clearPocketFlow());
    dispatch(pocketActions.updatePocket(null));
  };

  const modalContent = (
    <>
      {
        pocketContents({
          inputOne: {
            amount: paymentDetails?.inputOne?.amount,
            currency: selectedOption?.inputOne?.currency?.currency,
          },
          inputTwo: {
            amount: paymentDetails?.inputTwo?.amount,
            currency: selectedOption?.inputTwo?.currency?.currency,
          },
          planName: reduxViewedPlan?.coolName,
          goalName: reduxGoalInvestmentDtls?.goalName,
        })?.[type?.path]?.congratulationsModalContent
      }
    </>
  );

  const ErrorModalContent = <>{popupError?.alertMessage}</>;

  const insufficientFunds =
    pocketDetails &&
    Number(pocketDetails?.balance) <
      convertAmountToNumber(paymentDetails?.inputOne?.amount);

  const modalBody = {
    withdraw: transactionDetails,
    investment: (
      <PaymentMethodModalContent
        pocketDetails={pocketDetails}
        setActivePaymentMethod={setActivePaymentMethod}
        activePaymentMethod={activePaymentMethod}
        handlePaymentFromModal={() =>
          handlePaymentFromModal(
            pocketContents()?.[type?.path.toLowerCase()].action,
          )
        }
        closeModal={() => {
          setAlert(alertInitialState);
          setActivePaymentMethod(null);
          setOpenPaymentMethodModal(false);
        }}
        loading={loading}
        btnText={pocketContents()?.[type?.path]?.paymentModeButton}
        displayErrorMessage={
          popupError?.alertMessage ? <Alert alert={popupError} /> : null
        }
        insufficientFunds={insufficientFunds}
        paymentDetails={paymentDetails}
        modalTitle={modalTitle}
        setOpenPaymentMethodModal={setOpenPaymentMethodModal}
        planDetails={reduxViewedPlan}
        ndovuPercentage={investor?.subscription?.percentageFee}
        isPlan={true}
      />
    ),
    goal: (
      <PaymentMethodModalContent
        pocketDetails={pocketDetails}
        setActivePaymentMethod={setActivePaymentMethod}
        activePaymentMethod={activePaymentMethod}
        handlePaymentFromModal={() =>
          handlePaymentFromModal(
            pocketContents()?.[type?.path.toLowerCase()].action,
          )
        }
        closeModal={() => {
          setAlert(alertInitialState);
          setActivePaymentMethod(null);
          setOpenPaymentMethodModal(false);
        }}
        loading={loading}
        btnText={pocketContents()?.[type?.path]?.paymentModeButton}
        displayErrorMessage={
          popupError?.alertMessage ? <Alert alert={popupError} /> : null
        }
        insufficientFunds={insufficientFunds}
        paymentDetails={paymentDetails}
        modalTitle={modalTitle}
        goalDetails={reduxGoalInvestmentDtls}
        planDetails={reduxViewedPlan}
        ndovuPercentage={investor?.subscription?.percentageFee}
        isPlan={false}
      />
    ),
  };

  const onConversionPage = type?.path === conversion.action;

  const conversionAmountGreaterThanBalance =
    onConversionPage &&
    Number(pocketDetails?.balance) <
      convertAmountToNumber(paymentDetails?.inputOne?.amount);

  const balanceIsLessThanMinimumWithdrawableAmount = pocketBalanceIsLessThanMinimumWithdrawableAmount(
    pocketDetails?.balance,
    selectedOption?.inputOne?.currency?.minimumWithdrawableAmount,
    type?.path,
  );

  const withdrawingOnEmptyBalance =
    type?.path === withdraw.action && pocketDetails?.balance === 0;

  const sameCurrencyConversion =
    onConversionPage &&
    selectedOption?.inputOne?.currency?.currency ===
      selectedOption?.inputTwo?.currency?.currency;

  const iconName = pocketContents()?.[type?.path]?.actionIcon;

  const disableButton =
    sameCurrencyConversion ||
    alert?.alertMessage ||
    conversionAmountGreaterThanBalance ||
    !paymentDetails?.inputOne?.amount ||
    withdrawingOnEmptyBalance ||
    paymentDetails?.inputOne?.amount?.length === 0;

  const operationsForAllCurrencies = [
    pocketActionsEnum.INVESTMENT,
    pocketActionsEnum.GOAL,
    pocketActionsEnum.TOPUP,
  ];
  const previousPage = localStorage.getItem('investingPageEntryPoint');

  return (
    <AnimatedPageContainer>
      <Back
        text={'Back'}
        title={
          type?.path === pocketActionsEnum.INVESTMENT &&
          pocketFlow?.investmentType === investmentType.TOP_UP
            ? pocketContents()?.[type?.path]?.fundTopupTitle
            : pocketContents()?.[type?.path]?.title
        }
        previousPageLink={previousPage}
      />
      <MainWrapper>
        <form>
          <div className="bg-white">
            <div className="d-flex flex-column justify-content-center pt-10 px-4">
              <div className="text-center pocket-operation-icon-container mx-auto">
                {iconName ? (
                  <motion.div
                    animate={{ rotateY: 360 }}
                    transition={{
                      delay: 1,
                      duration: 2,
                      ease: 'linear',
                    }}
                  >
                    <img
                      src={require(`../../../icons/PocketActions/${iconName}-blue.svg`)}
                      alt="withdraw"
                      className="w-100 h-100"
                    />
                  </motion.div>
                ) : null}
              </div>
              <div className="operation-page-header mx-auto text-center text-18 font-weight-500 mt-5">
                {type?.path === pocketActionsEnum.INVESTMENT &&
                pocketFlow?.investmentType === investmentType.TOP_UP
                  ? pocketContents()?.[type?.path]?.fundTopupInputLabel
                  : pocketContents()?.[type?.path]?.inputLabel}
              </div>
              <div className="width-319 mx-auto mt-5">
                {onConversionPage ? (
                  <div className="ml-8 text-14 font-weight-500 mt-5">
                    Convert
                  </div>
                ) : (
                  <div className="d-flex justify-content-between width-319 font-weight-bolder mt-5">
                    <div className="width-45 text-14 font-weight-500 ml-8">
                      Amount
                    </div>
                    <div className="width-45 text-14 font-weight-500 ml-8">
                      Currency
                    </div>
                  </div>
                )}
                <div
                  className={`${
                    conversionAmountGreaterThanBalance
                      ? 'border-second-orange'
                      : 'border-gray-450'
                  } width-319 d-flex justify-content-between align-items-center border-radius-8 text-center mt-1 px-3 mx-auto`}
                >
                  <Input
                    placeholder={''}
                    type="text"
                    className={`${conversionAmountGreaterThanBalance &&
                      'text-second-orange'} width-45 py-4 border-0 bg-transparent font-weight-500`}
                    name={pocketContents()?.[type?.path]?.inputName}
                    defaultValue={
                      paymentDetails[pocketContents()?.[type?.path]?.inputName]
                        ?.amount
                    }
                    handleChange={handleChange}
                  />
                  <VerticalLine
                    classes={'border-r-gray-450 h-100 position-relative py-5'}
                  />

                  <div className="width-45">
                    <Dropdown
                      handleOpenSelector={handleDropdown}
                      dropdownPlaceholder={
                        pocketContents()?.[type]?.currencyPlaceholderFrom
                      }
                      selectedOption={
                        selectedOption[
                          pocketContents()?.[type?.path]?.inputName
                        ]?.currency
                      }
                      classes={'bg-transparent'}
                      name={pocketContents()?.[type?.path]?.inputName}
                      disableDropdown={type?.path === pocketActionsEnum.TOPUP}
                      withBorders={false}
                    />
                  </div>
                </div>
                {pocketDetails?.balance || pocketDetails?.balance === 0 ? (
                  <div className="text-center text-blue text-11 mt-5">
                    Pocket Balance:{' '}
                    <span className="font-weight-bolder">{`${
                      pocketDetails?.currency
                    } ${
                      balanceIsLessThanMinimumWithdrawableAmount
                        ? addCommas(
                            pocketDetails?.balance,
                            roundAmountToTwoDecimalNumbers,
                          )
                        : addCommas(pocketDetails?.balance)
                    }`}</span>
                  </div>
                ) : null}
              </div>

              <div className="mx-auto mt-2 width-319">
                {conversionAmountGreaterThanBalance ? (
                  <div
                    className={`${conversionAmountGreaterThanBalance &&
                      'text-second-orange'} font-weight-bold text-13`}
                  >
                    Insufficient balance in your pocket , kindly top up to
                    complete
                  </div>
                ) : alert?.alertMessage ? (
                  <Alert alert={alert} />
                ) : warningMessage?.alertMessage ? (
                  <Alert alert={warningMessage} />
                ) : sameCurrencyConversion ? (
                  <Alert
                    alert={{
                      alertMessage:
                        'Origin and new pocket currencies can not be the same',
                      alertMessageType: 'error',
                    }}
                  />
                ) : null}
              </div>

              {onConversionPage ? (
                <div className="width-319 mx-auto mt-5 mb-2">
                  <PaymentDetailsItem
                    fromCurrency={
                      selectedOption[pocketContents()?.[type?.path]?.inputName]
                        ?.currency?.currency
                    }
                    toCurrency={
                      selectedOption[pocketContents()?.[type?.path]?.inputName2]
                        ?.currency?.currency
                    }
                    rate={paymentDetails?.rate}
                    amount={
                      paymentDetails[pocketContents()?.[type?.path]?.inputName]
                        ?.amount
                    }
                  />
                </div>
              ) : null}

              {onConversionPage ? (
                <div className="width-319 mx-auto">
                  {onConversionPage ? (
                    <div className="ml-8 font-weight-bolder text-15 mt-5">
                      To
                    </div>
                  ) : null}
                  <div
                    className={`${
                      conversionAmountGreaterThanBalance
                        ? 'border-second-orange'
                        : 'border-gray-450'
                    } width-319 d-flex justify-content-between align-items-center rounded text-center px-3 mt-1 mx-auto`}
                  >
                    <Input
                      placeholder={''}
                      type="text"
                      className={`${conversionAmountGreaterThanBalance &&
                        'text-second-orange'} width-45 py-4 border-0 font-weight-bold font-weight-500`}
                      name={pocketContents()?.[type?.path]?.inputName2}
                      defaultValue={
                        paymentDetails[
                          pocketContents()?.[type?.path]?.inputName2
                        ]?.amount
                      }
                      handleChange={handleChange}
                      disabled={true}
                      id={'remove-disabled-style'}
                    />

                    <VerticalLine
                      classes={
                        'border-1-right-blue h-100 position-relative py-5'
                      }
                    />

                    <div className="width-45">
                      <Dropdown
                        handleOpenSelector={handleDropdown}
                        dropdownPlaceholder={
                          pocketContents()?.[type]?.currencyPlaceholderTo
                        }
                        selectedOption={
                          selectedOption[
                            pocketContents()?.[type?.path]?.inputName2
                          ]?.currency
                        }
                        classes={'bg-transparent py-4'}
                        name={pocketContents()?.[type?.path]?.inputName2}
                        withBorders={false}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <ButtonsContainer>
              <ConfirmButton
                buttonText={pocketContents()?.[type?.path]?.buttonText1}
                handleOnClick={() =>
                  handleSubmit(pocketContents()?.[type?.path]?.action)
                }
                disable={disableButton}
                loading={loading}
                buttonWidth={'button-md-w-360 button-w-360'}
                buttonIcon={`/media/svg/ndovu/${
                  pocketContents()?.[type?.path]?.buttonIcon
                }.svg`}
              />
            </ButtonsContainer>

            {onConversionPage ? (
              <Note
                classes={
                  'width-319 mx-auto mt-5 bg-orange-200 border-orange-600 p-3 border-radius-7 text-13'
                }
                title={'Note:'}
                content={
                  'The final converted amount will be deposited into your ndovu pocket.'
                }
                titleColor="text-red-200"
                contentColor="text-red-200"
                alignText="text-left"
              />
            ) : null}
          </div>
        </form>
      </MainWrapper>

      <ReusableModal
        open={openPaymentMethodModal}
        setOpen={() => {
          setOpenPaymentMethodModal(false);
          setAlert(alertInitialState);
        }}
        text={modalBody[type?.path]}
      />

      <CommonSuccessModal
        open={openCongratulationsModal}
        setOpen={() => setOpenCongratulationsModal(false)}
        content={modalContent}
        moreClasses={'bg-white'}
        handleButtonClick={() => {
          clearState();
          history.push(`/pocket/${pocketDetails?.id}`);
        }}
        iconName={pocketContents()?.[type?.path]?.modalIconName}
      />

      <ErrorModal
        open={openErrorModal}
        setOpen={setOpenErrorModal}
        content={ErrorModalContent}
        moreClasses="bg-orange-100"
        clearState={() => {
          setOpenErrorModal(false);
        }}
      />

      <CurrenciesModal
        open={openDropdown[pocketContents()?.[type?.path]?.inputName2]}
        handleClose={() => closeDropdown()}
        items={currencies}
        handleSelect={handleSelectOption}
        selectedItem={
          selectedOption[pocketContents()?.[type?.path]?.inputName2]?.currency
        }
        name={pocketContents()?.[type?.path]?.inputName2}
      />

      <CurrenciesModal
        open={openDropdown[pocketContents()?.[type?.path]?.inputName]}
        handleClose={() => closeDropdown()}
        items={
          operationsForAllCurrencies?.includes(type?.path)
            ? currencies
            : pockets
        }
        handleSelect={handleSelectOption}
        selectedItem={
          selectedOption[pocketContents()?.[type?.path]?.inputName]?.currency
        }
        name={pocketContents()?.[type?.path]?.inputName}
      />
    </AnimatedPageContainer>
  );
};

export default PocketOperations;
