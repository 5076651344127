import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import * as profile from '../../Profile/_redux/profileRedux';
import { requestEmailVerification } from '../_redux/authCrud';
import * as auth from '../_redux/authRedux';

import { setIsReferralCompleted } from '../helpers/setIsReferralCompleted';
import { updateLoginState } from '../helpers/udpateLoginState';

import { emailVerificationErrors } from '../../../helpers/constants';
import Loader from '../../Common/Loader';
import { ConfirmButton } from '../../Common/ReusableButtons';

const SignupEmailVerification = props => {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState({
    message: null,
    code: null,
  });

  const searchParams = new URLSearchParams(props.location.search);
  const verificationCode = searchParams.get('code');
  const investorEmail = searchParams.get('email');

  const userProfileInformation = useSelector(state => state.profile);

  const verifyEmail = async () => {
    if (!investorEmail || !verificationCode) {
      setErrorMessage({
        code: emailVerificationErrors.MISSING_VERIFICATION_DETAILS,
        message: 'Verification details are missing',
      });
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const payload = {
        code: verificationCode,
        email: investorEmail,
      };
      const { data } = await requestEmailVerification(payload);
      setIsReferralCompleted(data.user?.investors[0]?.isReferralCompleted);

      updateLoginState(
        data,
        props?.login,
        props.profileAction,
        userProfileInformation,
      );

      setLoading(false);
    } catch (error) {
      setErrorMessage({
        code: error?.response?.data && error.response.data.code,
        message: error?.response?.data
          ? error.response.data.message
          : 'Verification not successful',
      });

      setLoading(false);
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  const emailVerificationErrorMessages = {
    [emailVerificationErrors.ACCOUNT_IS_ALREADY_VERIFIED]: {
      title: 'Your Account is already verified',
      description: 'Kindly sign in to your account.',
      buttonText: 'Sign In',
      buttonLink: '/auth/login',
    },

    [emailVerificationErrors.USER_NOT_FOUND]: {
      title: 'No user registered with provided information',
      description: 'Create account instead.',
      buttonText: 'Create account',
      buttonLink: '/auth/select-sign-up-option',
    },
    [emailVerificationErrors.INVALID_OTP_CODE]: {
      title: 'Verification Error',
      description: 'Kindly confirm your verification code.',
      buttonText: 'Resend Verification Email',
      buttonLink: '/auth/resendVerification',
    },

    [emailVerificationErrors.VERIFICATION_CODE_EXPIRED]: {
      title: 'Verification Error',
      description:
        'Kindly request a new verification email by clicking the button bellow.',
      buttonText: 'Resend Verification Email',
      buttonLink: '/auth/resendVerification',
    },
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {errorMessage?.code &&
          emailVerificationErrorMessages[errorMessage?.code] ? (
            <div className="text-center" style={{ maxWidth: '390px' }}>
              <h3 className="font-size-h3">
                {emailVerificationErrorMessages[errorMessage?.code]?.title}
              </h3>

              <p>
                {
                  emailVerificationErrorMessages[errorMessage?.code]
                    ?.description
                }
              </p>

              {errorMessage?.message ? (
                <h6 className="mt-10" style={{ color: '#F05B7F' }}>
                  {errorMessage?.message}
                </h6>
              ) : null}

              <div className="text-center">
                <Link
                  to={
                    emailVerificationErrorMessages[errorMessage?.code]
                      ?.buttonLink
                  }
                >
                  <ConfirmButton
                    buttonText={
                      emailVerificationErrorMessages[errorMessage?.code]
                        ?.buttonText
                    }
                    loading={loading}
                    classes={'my-8'}
                  />
                </Link>
              </div>
            </div>
          ) : errorMessage?.message ? (
            <div>
              <div className="text-center">
                <h3 className="font-size-h3">Verification Error</h3>
                <p>{errorMessage?.message}</p>
              </div>

              <Link to="/auth/resendVerification">
                <ConfirmButton
                  buttonText="Resend Verification Email"
                  classes={
                    'mx-auto justify-content-center align-items-center d-flex mt-8'
                  }
                />
              </Link>
            </div>
          ) : (
            <div>
              <div className="text-center">
                <h3 className="font-size-h3">Successfully Verified</h3>
                <p>Kindly sign in to your account</p>
              </div>

              <Link to="/auth/login">
                <ConfirmButton
                  buttonText="Sign In"
                  classes={
                    'mx-auto justify-content-center align-items-center d-flex mt-8'
                  }
                />
              </Link>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default injectIntl(
  connect(null, { ...auth.actions, ...profile.actions })(
    SignupEmailVerification,
  ),
);
