export const updateLoginState = (
  userDetails,
  updateLoginAction,
  updateProfileAction,
  profile,
) => {
  const investorInformation = userDetails.user?.investors[0];
  updateLoginAction(userDetails);
  delete userDetails.user?.investors;

  updateProfileAction({
    ...profile,
    user: userDetails.user,
    investor: investorInformation,
  });
};
