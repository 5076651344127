import React from 'react';
import { Link } from 'react-router-dom';

import elephant from '../../../images/elephantLogo.svg';
import { ConfirmButton } from '../../Common/ReusableButtons';
import { NdovuLogo } from '../../Common/NdovuLogo';

const AfterRegMessage = () => {
  return (
    <>
      <div>
        <div className="text-center">
          <NdovuLogo image={elephant} />

          <h3 className="my-5 font-size-h4" style={{ color: '#0071CE' }}>
            Thank you for choosing ndovu
          </h3>

          <div className="font-weight-bold">
            <p className="px-lg-20 px-md-20">
              Within the next hour, a verification email will be sent to your
              specified email address.{' '}
              <strong>Please check spam, junk or promotion folders.</strong>{' '}
            </p>

            <div>
              <Link to="/auth/signin">
                <ConfirmButton buttonText="Sign in" buttonWidth={'width-140'} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AfterRegMessage;
