import React from 'react';
import { motion } from 'framer-motion/dist/framer-motion';

import { PortfolioValueOrPocket } from '../PortfolioValueOrPocket';
import portfolioValueIcon from '../../../../icons/dashboard/portfolioValue.svg';
import pocketIcon from '../../../../icons/dashboard/pocket.svg';
import { moneyFormat } from '../../../../helpers/moneyFormat';

export const PortfolioValueAndPocket = ({ portfolioValueDetails }) => {
  const {
    currency,
    portfolioValue,
    pocketBalance,
    pocketId,
    currencyFlag,
    totalPendingAmount,
  } = portfolioValueDetails;

  return (
    <motion.div
      className="min-width-289 border-light-blue-540 px-5 pt-5 pb-7 border-radius-20"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.6,
        scale: {
          type: 'spring',
          damping: 15,
          stiffness: 50,
          restDelta: 0.001,
        },
      }}
    >
      <div className="d-flex align-items-center">
        <div className="card-flag border-radius-5">
          <img
            className="border-radius-5"
            src={currencyFlag}
            alt="country flag"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </div>

        <div className="ml-3 font-weight-600 text-13">{currency}</div>
      </div>

      <PortfolioValueOrPocket
        icon={portfolioValueIcon}
        label={'Portfolio'}
        amount={portfolioValue}
        link={'/portfolio'}
        eventName="Open_Profile"
      />

      <PortfolioValueOrPocket
        icon={pocketIcon}
        label={'Pocket'}
        amount={pocketBalance}
        link={`/pocket/${pocketId}`}
        isPocket={true}
      />

      {totalPendingAmount > 0 ? (
        <div className="text-orange-400 text-13 font-weight-500 text-center mt-5 mb-n3">
          Pending Amount: {moneyFormat(totalPendingAmount)}
        </div>
      ) : null}
    </motion.div>
  );
};
