import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { actions as planActions } from '../../../Plan/_redux/planReducers';
import { actions as goalActions } from '../../../Goal/_redux/goalReducers';
import {
  InvestmentAction,
  customerIOEventType,
  paymentMethod,
  pocketActionsEnum,
} from '../../../../helpers/constants';
import convertAmountToNumber from '../../../../helpers/convertAmountToNumber';
import { findCurrency } from '../../../../helpers/findCurreny';
import Alert from '../../../Common/Alert';
import WarningMessage from '../../../Common/WarningMessage';
import { goalTopUp, investmentInGoal } from '../../../Goal/_redux/goalActions';
import { directInvestment, fundTopUp } from '../../../Plan/_redux/plansActions';
import {
  getBankAccountDetails,
  payWithBankTransfer,
} from '../../_redux/investmentAction';
import './PayWithBankTransfer.scss';
import BankDetails from './bankDetails';
import { Instructions } from './Instructions';
import { PayWithBankTransferCardsWrapper } from './PayWithBankTransferCardsWrapper';
import { moneyFormat } from '../../../../helpers/moneyFormat';
import { ExclamationMark } from '../../../Common/ExclamationMark';
import { calculateNdovuFee } from '../../../../helpers/calculateNdovuFee';
import { topupPocket } from '../../../Pocket/_redux/axiosRequest';
import { pocketContents } from '../../../Pocket/copies';
import { CommonSuccessModal } from '../../../Common/CommonSuccessModal';
import { scrollToTop } from '../../../../helpers/scrollToTop';
import { CancelButton, ConfirmButton } from '../../../Common/ReusableButtons';
import { ButtonsContainer } from '../../../Common/ButtonsContainer';
import { DetailsItem } from '../../../Common/DetailsItem';
import CheckBox from '../../../Common/CheckBox';
import { eventTracking } from '../../../../eventTracking/httpRequest';

const handleGoalInvestment = async (
  id,
  textReference,
  channel,
  transactionId,
) => {
  const response = await investmentInGoal(
    parseInt(id),
    textReference,
    channel,
    parseInt(transactionId),
  );
  return response;
};

const handleDirectInvestment = async (
  id,
  textReference,
  channel,
  transactionId,
) => {
  const response = await directInvestment(
    parseInt(id),
    textReference,
    channel,
    parseInt(transactionId),
  );
  return response;
};

const handleFundTopUp = async (planSubscribtionId, textReference, channel) => {
  const response = await fundTopUp(
    parseInt(planSubscribtionId),
    textReference,
    channel,
  );
  return response;
};

const handleGoalTopUp = async (id, textReference, channel) => {
  const response = await goalTopUp(parseInt(id), textReference, channel);
  return response;
};

const {
  DIRECT_INVESTMENT,
  DIRECT_INVESTMENT_TOPUP,
  GOAL_INVESTMENT,
  GOAL_TOPUP,
  POCKET_TOPUP,
} = InvestmentAction;

const actions = [
  { name: DIRECT_INVESTMENT, invest: handleDirectInvestment },
  { name: DIRECT_INVESTMENT_TOPUP, invest: handleFundTopUp },
  { name: GOAL_INVESTMENT, invest: handleGoalInvestment },
  { name: GOAL_TOPUP, invest: handleGoalTopUp },
];

const PayWithBankTransfer = ({
  totalAmount,
  discount,
  amount,
  currency,
  setPayWithBankTransfer,
}) => {
  // starts here
  const { investor } = useSelector(state => state.profile);

  // ends here
  const history = useHistory();
  const [promoCode, setPromoCode] = useState();
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [loading, setLoading] = useState(false);
  const [checked, handleCheckBoxChange] = useState(false);
  const [account, setAccount] = useState({});
  const [openCongratulationsModal, setOpenCongratulationsModal] = useState(
    false,
  );

  const investmentAction = useSelector(
    state => state?.investment?.investmentAction,
  );

  const reduxPlanInvestmentDtls = useSelector(
    state => state?.plan?.planInvestmentDetails,
  );

  const reduxGoalInvestmentDtls = useSelector(
    state => state?.goal?.goalInvestmentDetails,
  );

  const pocketDetails = useSelector(state => state.pocketDetails);

  const { currencies: allCurrencies } = useSelector(state => state?.currencies);
  const { goalInvestmentDetails } = useSelector(state => state.goal);
  const { viewedPlan: planDetails } = useSelector(state => state.plan);

  const dispatch = useDispatch();

  const localStorageData = JSON.parse(localStorage.getItem('goalDiscountInfo'));
  const discountedAmount = localStorageData?.discountAmount;
  const ndovuPercentage = investor?.subscription.percentageFee;

  const calculateInvestedAmount = () => {
    const ndovuFee = calculateNdovuFee(
      goalInvestmentDetails?.goalId,
      amount,
      planDetails,
      discountedAmount,
      ndovuPercentage,
    );
    const investedAmount =
      convertAmountToNumber(amount) - Number(ndovuFee).toFixed(2);
    return investedAmount;
  };

  const handleCheckBankTransferBoxChange = () => {
    handleCheckBoxChange(!checked);
  };

  useEffect(() => {
    scrollToTop();
    const promoCode = localStorage.getItem('promoCode');
    setPromoCode(promoCode);
  }, []);

  useEffect(() => {
    const selectedCurrency = findCurrency(allCurrencies, currency);
    const getData = async () => {
      try {
        const {
          data: { data },
        } = await getBankAccountDetails(selectedCurrency?.id);
        setAlertOn(false);
        setAccount(data);
      } catch (error) {
        setAlertOn(true);
        setAlert({
          alertMessage: error.response?.data?.message,
          alertMessageType: 'error',
        });
      }
    };

    getData();
  }, [allCurrencies, currency]);

  const attention = (
    <div className="d-flex align-items-center">
      <ExclamationMark />

      <span className="text-12 ml-3">
        Select the checkbox below once you finalize the transfer. Transactions
        that don’t match{' '}
        <span className="font-weight-700">
          exact amount and reference number
        </span>{' '}
        could be cancelled.
      </span>
    </div>
  );

  const displayWarning = () => {
    return (
      <div className="mt-5">
        <WarningMessage
          message={attention}
          background="bg-orange-150"
          borderColor="border-primary-orange"
        />

        <div className="pt-3">
          <CheckBox
            checked={checked}
            label={`I have transferred the money`}
            name={''}
            handleCheckBoxChange={handleCheckBankTransferBoxChange}
          />
        </div>
      </div>
    );
  };

  const goalOrPlanId = () => {
    if (
      investmentAction === DIRECT_INVESTMENT ||
      investmentAction === DIRECT_INVESTMENT_TOPUP
    ) {
      return Number(reduxPlanInvestmentDtls?.planId);
    } else if (
      investmentAction === GOAL_INVESTMENT ||
      investmentAction === GOAL_TOPUP
    ) {
      return Number(reduxGoalInvestmentDtls?.goalId);
    }
  };

  const handleError = errorMessage => {
    setLoading(false);
    setAlertOn(true);
    setAlert({
      alertMessage: errorMessage,
      alertMessageType: 'error',
    });
    scrollToTop();
  };

  const modalContent = (
    <>
      {
        pocketContents({
          inputOne: {
            amount: moneyFormat(amount),
            currency: currency,
          },
        })?.[pocketActionsEnum.TOPUP]?.congratulationsModalContent
      }
    </>
  );

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await eventTracking(customerIOEventType.PAYMENT_CONFIRMATION);
    } catch (error) {}

    const pocketId = pocketDetails?.id;

    if (investmentAction === POCKET_TOPUP) {
      const payload = {
        paymentOption: paymentMethod.BANK_TRANSFER,
        amount: convertAmountToNumber(amount),
        currency: currency,
        promoCode,
        pocketId: Number(pocketId),
      };

      try {
        await topupPocket(payload);
        setOpenCongratulationsModal(true);
        setLoading(false);

        return;
      } catch (error) {
        handleError(error.response?.data?.message);
        return;
      }
    }

    try {
      const response = await payWithBankTransfer(
        convertAmountToNumber(amount),
        currency,
        promoCode,
      );
      const { tx_ref } = response?.data;
      const id = goalOrPlanId();

      actions.forEach(async item => {
        if (item.name === investmentAction) {
          try {
            await item.invest(id, tx_ref, paymentMethod.BANK_TRANSFER);
            setLoading(false);
            localStorage.removeItem('discountInfo');
            localStorage.removeItem('promoCode');

            investmentAction === GOAL_INVESTMENT ||
            investmentAction === GOAL_TOPUP
              ? dispatch(goalActions?.ResetGoalInvestmentDetails())
              : dispatch(planActions?.ResetPlanInvestmentDetails());

            history.push('/congratulations');
          } catch (error) {
            handleError(error.response?.data?.message);
          }
        }
      });
    } catch (error) {
      handleError(
        error.response?.data?.message || error.response?.data?.errorMessage,
      );
    }
  };

  const isPocketTopup = investmentAction === InvestmentAction.POCKET_TOPUP;

  return (
    <div className="py-md-10 py-5 px-5">
      <div className="big-container mx-auto border-light-blue-545 border-radius-8">
        {alertOn && (
          <div className="mb-5">
            <Alert alert={alert} />
          </div>
        )}
        <div className="px-md-5 px-3 py-5">
          <div className="text-center mb-5">
            <h1 className="mb-10 text-20 font-weight-600">
              Pay with Bank Transfer
            </h1>
          </div>

          <Instructions />

          <BankDetails account={account} />

          <PayWithBankTransferCardsWrapper>
            <div className="account-container bg-white border-radius-9">
              <DetailsItem
                label={'Transfer Reference Code'}
                value={investor?.causal ? `NV-${investor?.causal}` : '---'}
                changeValueString={false}
              />

              <DetailsItem
                label={'Total Deposit Amount'}
                value={`${moneyFormat(amount)} ${currency}`}
                labelAdditionalClasses={'text-blue'}
                valueAdditionalClasses={'text-blue'}
                changeValueString={false}
              />

              {!isPocketTopup ? (
                <>
                  <DetailsItem
                    label={'Amount that will be invested'}
                    value={`${moneyFormat(
                      calculateInvestedAmount(),
                    )} ${currency}`}
                    changeValueString={false}
                  />

                  <DetailsItem
                    label={'ndovu Fee'}
                    value={`-${moneyFormat(
                      calculateNdovuFee(
                        goalInvestmentDetails?.goalId,
                        amount,
                        planDetails,
                        discountedAmount,
                        ndovuPercentage,
                      ),
                    )}
                         ${currency}`}
                    changeValueString={false}
                  />
                </>
              ) : null}
            </div>
          </PayWithBankTransferCardsWrapper>

          {displayWarning()}

          <ButtonsContainer justifyContent="justify-content-between">
            <CancelButton
              buttonText="Cancel"
              handleOnClick={() => setPayWithBankTransfer(false)}
              twinButton={true}
            />

            <ConfirmButton
              buttonText="CONFIRM PAYMENT"
              loading={loading}
              handleOnClick={handleSubmit}
              disable={!checked}
              twinButton={true}
            />
          </ButtonsContainer>
        </div>
      </div>

      <CommonSuccessModal
        open={openCongratulationsModal}
        setOpen={() => setOpenCongratulationsModal(false)}
        title={'Processing'}
        content={modalContent}
        moreClasses={'bg-white'}
        handleButtonClick={() => {
          history.push(`/pocket/${pocketDetails?.id}`);
        }}
        iconName={'sand-glass'}
      />
    </div>
  );
};

export default PayWithBankTransfer;
