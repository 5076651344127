import * as Yup from 'yup';
import {
  allowWhiteSpace,
  allowWhiteSpaceError,
  passwordRegex,
  passwordValidationMessage,
} from '../../../helpers/constants';

export const loginSchema = intl => {
  return Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),

    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(40, 'Maximum 40 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
  });
};

export const signupSchema = intl => {
  return Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(50, 'Maximum 50 symbols')
      .matches(allowWhiteSpace, allowWhiteSpaceError)
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),

    lastName: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(50, 'Maximum 50 symbols')
      .matches(allowWhiteSpace, allowWhiteSpaceError)
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),

    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),

    password: Yup.string()
      .matches(passwordRegex, passwordValidationMessage)
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),

    changePassword: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      )
      .when('password', {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          "Password and Confirm Password didn't match",
        ),
      }),

    phoneNumber: Yup.number().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      }),
    ),

    acceptTerms: Yup.bool().required(
      'You must accept the terms and conditions',
    ),
  });
};

export const signupSchemaForGoogleSignup = intl => {
  return Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(50, 'Maximum 50 symbols')
      .matches(allowWhiteSpace, allowWhiteSpaceError)
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),

    lastName: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(50, 'Maximum 50 symbols')
      .matches(allowWhiteSpace, allowWhiteSpaceError)
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),

    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),

    phoneNumber: Yup.number().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      }),
    ),

    acceptTerms: Yup.bool().required(
      'You must accept the terms and conditions',
    ),
  });
};

export const phoneNumberVerificationSchema = intl => {
  return Yup.object().shape({
    phoneNumber: Yup.number().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      }),
    ),
  });
};
