import React from 'react';
import { Route, Switch } from 'react-router-dom';
import WithdrawalAccounts from './WithdrawalAccounts';
import Settlement from './Settlement';
import SettlementNotification from './SettlementNotification';
import '../index.scss';

const ManageAccount = ({ match }) => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}/notification`}
        component={SettlementNotification}
      />
      <Route
        exact
        path={`${match.path}/accounts`}
        component={WithdrawalAccounts}
      />

      <Route
        exact
        path={`${match.path}/details/:settlementId`}
        component={Settlement}
      />
    </Switch>
  );
};

export default ManageAccount;
