import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Alert from '../../Common/Alert';

import * as Yup from 'yup';
import CustomInput from '../../Common/CustomInput';
import FileUpload from '../../Business/helpers/FileUpload';
import { downloadUsersFile } from '../../Profile/_redux/profileCrud';

import { injectIntl } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { countryCode } from '../../../helpers/constants';
import {
  compressImage,
  generateImageDataUrl,
  isFileNameValid,
  theFileIsLessThanOrEqualToMaximumSize,
  validateFile,
} from '../../../helpers/validateFiles';
import { ValidationErrors } from '../../Business/components/ValidationErrors';
import { UploadedFile } from '../../Business/helpers/UploadedFile';
import { CommonSuccessModal } from '../../Common/CommonSuccessModal';
import { KycCompletedModalContent } from '../../Common/KycCompletedModalContent';
import { KycPageContentWrapper } from '../../Common/KycPageContentWrapper/KycPageContentWrapper';
import { Note } from '../../Common/Note';
import { sendFormDataToBackend } from '../../Individual/_redux/individualKYCAction';
import * as kyc from '../../KYC/_redux/kycRedux';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { ButtonsContainer } from '../../Common/ButtonsContainer';

const UploadDocuments = props => {
  let history = useHistory();
  const { handleStepChange, id } = props;

  const { investor } = useSelector(state => state.profile);

  const [uploading, setUploading] = useState({
    identificationFrontSide: false,
    taxPinCertificate: false,
    facePhoto: false,
    proofOfAddress: false,
  });
  const [frontFileNameError, setFrontFileNameError] = useState(null);
  const [taxPinCertificateError, setTaxPinCertificateError] = useState(null);
  const [facePhotoError, setFacePhotoError] = useState(null);
  const [proofOfAddressError, setProofOfAddressError] = useState(null);
  const [frontIsValid, setFrontIsValid] = useState(true);
  const [facePhotoIsValid, setFacePhotoIsValid] = useState(true);
  const [proofOfAddressIsValid, setProofOfAddressIsValid] = useState(true);
  const [taxPinCertificateIsValid, setTaxPinCertificateIsValid] = useState(
    true,
  );
  const [error, setError] = useState();

  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const [selectedFront, setSelectedFront] = useState();
  const [selectedFrontImageDataUrl, setSelectedFrontImageDataUrl] = useState();
  const [selectedFacePhoto, setSelectedFacePhoto] = useState();
  const [facePhotoDataUrl, setFacePhotoDataUrl] = useState();

  const [
    selectedProofOfAddressImageDataUrl,
    setSelectedProofOfAddressImageDataUrl,
  ] = useState();

  const [
    selectedTaxPinCertificateImageDataUrl,
    setSelectedTaxPinCertificateImageDataUrl,
  ] = useState();

  const [selectedTaxPinCertificate, setSelectedTaxPinCertificate] = useState();
  const [selectedProofOfAddress, setSelectedProofOfAddress] = useState();

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const kycInfo = useSelector(state => state.kyc);

  const dispatch = useDispatch();

  const FILE_SIZE = 5 * 1024 * 1024;

  useEffect(() => {
    handleStepChange({ next: id });
  }, []);

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const handleOpeningFile = async link => {
    downloadUsersFile(link)
      .then(response => {
        const file = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      })
      .catch(() => {
        setAlert('Failed to download this document');
      });
  };

  const handleCardUpload = async (e, name) => {
    setAlert({
      alertMessage: null,
      alertMessageType: null,
    });

    const file = e.target.files[0];
    setFrontFileNameError(null);
    setTaxPinCertificateError(null);
    setFacePhotoError(null);

    if (!file) {
      return;
    }

    if (name === 'identificationFrontSide') {
      // Validate File name
      const result = isFileNameValid(file.name);
      if (result?.error) {
        setFrontFileNameError(result?.error);
        return;
      }

      setUploading({ identificationFrontSide: true });

      // Validate file extension
      const validationError = await validateFile(file)
        .then(validation => {
          return validation;
        })
        .catch(error => {
          return { valid: false, error: error?.message };
        });

      if (!validationError.valid) {
        setFrontFileNameError(validationError?.error);
        setUploading({ identificationFrontSide: false });
        return;
      }

      let newFile;

      if (file.type === 'application/pdf') {
        if (FILE_SIZE < file?.size) {
          setFrontFileNameError('ID should be Max 5MB');
          setUploading({ identificationFrontSide: false });
          return;
        }
        newFile = file;
      } else {
        // Reduce the file size to 5MB
        newFile = await compressImage(file);
      }

      if (!theFileIsLessThanOrEqualToMaximumSize(newFile?.size, FILE_SIZE)) {
        setFrontFileNameError('ID should be Max 5MB');
        setUploading({ identificationFrontSide: false });
        return;
      }

      setSelectedFront(newFile);
      setFacePhotoIsValid(true);
      setFrontFileNameError(null);
      frontIsValid && setError(null);

      // This is used to view file before sending it to the BE
      generateImageDataUrl(newFile)
        .then(fileDataUrl => {
          setSelectedFrontImageDataUrl(fileDataUrl);
          setUploading({ identificationFrontSide: false });
        })
        .catch(error => {
          console.error('Error reading file as data URL:', error);
          setUploading({ identificationFrontSide: false });
        });
    }

    if (name === 'facePhoto') {
      // Validate File name
      const result = isFileNameValid(file.name);
      if (result?.error) {
        setFacePhotoError(result?.error);
        return;
      }

      setUploading({ facePhoto: true });

      // Validate file extension
      const validationError = await validateFile(file)
        .then(validation => {
          return validation;
        })
        .catch(error => {
          return { valid: false, error: error?.message };
        });

      if (!validationError.valid) {
        setFacePhotoError(validationError?.error);
        setUploading({ facePhoto: false });
        return;
      }

      let newFile;

      if (file.type === 'application/pdf') {
        if (FILE_SIZE < file?.size) {
          setFacePhotoError('Face Photo should be Max 5MB');
          setUploading({ facePhoto: false });
          return;
        }
        newFile = file;
      } else {
        // Reduce the file size to 5MB
        newFile = await compressImage(file);
      }

      if (!theFileIsLessThanOrEqualToMaximumSize(newFile?.size, FILE_SIZE)) {
        setFacePhotoError('Face Photo should be Max 5MB');
        setUploading({ facePhoto: false });
        return;
      }

      setSelectedFacePhoto(newFile);

      // This is used to view file before sending it to the BE
      generateImageDataUrl(newFile)
        .then(fileDataUrl => {
          setFacePhotoDataUrl(fileDataUrl);
          setUploading({ facePhoto: false });
        })
        .catch(error => {
          console.error('Error reading file as data URL:', error);
          setUploading({ facePhoto: false });
        });
      setFrontIsValid(true);
      setFacePhotoError(null);
      facePhotoIsValid && setError(null);
    }

    if (name === 'proofOfAddress') {
      // Validate File name
      const result = isFileNameValid(file.name);
      if (result?.error) {
        setProofOfAddressError(result?.error);
        return;
      }

      setUploading({ proofOfAddress: true });

      // Validate file extension
      const validationError = await validateFile(file)
        .then(validation => {
          return validation;
        })
        .catch(error => {
          return { valid: false, error: error?.message };
        });

      if (!validationError.valid) {
        setProofOfAddressError(validationError?.error);
        setUploading({ proofOfAddress: false });
        return;
      }

      let newFile;

      if (file.type === 'application/pdf') {
        if (FILE_SIZE < file?.size) {
          setProofOfAddressError('Proof of Address should be Max 5MB');
          setUploading({ proofOfAddress: false });
          return;
        }
        newFile = file;
      } else {
        // Reduce the file size to 5MB
        newFile = await compressImage(file);
      }

      if (!theFileIsLessThanOrEqualToMaximumSize(newFile?.size, FILE_SIZE)) {
        setProofOfAddressError('Proof of Address should be Max 5MB');
        setUploading({ proofOfAddress: false });
        return;
      }

      setSelectedProofOfAddress(newFile);

      // This is used to view file before sending it to the BE
      generateImageDataUrl(newFile)
        .then(fileDataUrl => {
          setSelectedProofOfAddressImageDataUrl(fileDataUrl);
          setUploading({ proofOfAddress: false });
        })
        .catch(error => {
          console.error('Error reading file as data URL:', error);
          setUploading({ proofOfAddress: false });
        });

      setProofOfAddressIsValid(true);
      setProofOfAddressError(null);
      proofOfAddressIsValid && setError(null);
    }

    if (name === 'taxPinCertificate') {
      setUploading({ taxPinCertificate: true });
      // Validate File name
      const result = isFileNameValid(file.name);
      if (result?.error) {
        setTaxPinCertificateError(result?.error);
        setUploading({ taxPinCertificate: false });
        return;
      }

      // Validate file extension
      const validationError = await validateFile(file)
        .then(validation => {
          return validation;
        })
        .catch(error => {
          return { valid: false, error: error?.message };
        });

      if (!validationError.valid) {
        setTaxPinCertificateError(validationError?.error);
        setUploading({ taxPinCertificate: false });
        return;
      }

      let newFile;

      if (file.type === 'application/pdf') {
        if (FILE_SIZE < file?.size) {
          setTaxPinCertificateError('Certificate should be Max 5MB');
          setUploading({ taxPinCertificate: false });
          return;
        }
        newFile = file;
      } else {
        // Reduce the file size to 5MB
        newFile = await compressImage(file);
      }

      if (!theFileIsLessThanOrEqualToMaximumSize(newFile?.size, FILE_SIZE)) {
        setTaxPinCertificateError('Certificate should be Max 5MB');
        setUploading({ taxPinCertificate: false });
        return;
      }

      setSelectedTaxPinCertificate(newFile);

      // This is used to view file before sending it to the BE
      generateImageDataUrl(newFile)
        .then(fileDataUrl => {
          setSelectedTaxPinCertificateImageDataUrl(fileDataUrl);
          setUploading({ taxPinCertificate: false });
        })
        .catch(error => {
          console.error('Error reading file as data URL:', error);
          setUploading({ taxPinCertificate: false });
        });

      setTaxPinCertificateIsValid(true);
      setTaxPinCertificateError(null);
      taxPinCertificateIsValid && setError(null);
    }
  };

  const initialValues = {
    taxPayerNumber: investor?.taxPayerNumber || '',
  };

  const DetailsSchema = Yup.object().shape({
    taxPayerNumber: Yup.string().required(
      'Tax Payer Number Number is required',
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: DetailsSchema,
    onSubmit: async values => {
      const formData = new FormData();

      for (const key in kycInfo) {
        if (Object.hasOwnProperty.call(kycInfo, key)) {
          formData.append(key, kycInfo[key]);
        }
      }

      formData.append('taxPayerNumber', values.taxPayerNumber);

      // Append selected files

      if (selectedFront) {
        formData.append('identificationFrontSide', selectedFront);
      } else if (!investor?.identificationFrontSide) {
        setAlert({
          alertMessage: 'Please upload Identity Document',
          alertMessageType: 'error',
        });
        return;
      }

      if (selectedFacePhoto) {
        formData.append('facePhoto', selectedFacePhoto);
      } else if (!investor?.facePhoto) {
        setAlert({
          alertMessage: 'Please upload Passport Size Photo',
          alertMessageType: 'error',
        });
        return;
      }

      if (selectedTaxPinCertificate) {
        formData.append('taxPinCertificate', selectedTaxPinCertificate);
      } else if (
        investor?.kycLevel.level >= 2 &&
        !investor?.taxPinCertificate &&
        investor?.country === 'KE'
      ) {
        setAlert({
          alertMessage: 'Please upload Tax Pin Certificate',
          alertMessageType: 'error',
        });
        return;
      }

      if (selectedProofOfAddress) {
        formData.append('proofOfAddress', selectedProofOfAddress);
      } else if (investor?.kycLevel.level === 3 && !investor?.proofOfAddress) {
        setAlert({
          alertMessage: 'Please upload Proof of Address',
          alertMessageType: 'error',
        });
        return;
      }

      try {
        setLoading(true);
        const response = await sendFormDataToBackend(formData);
        if (response && response.data) {
          dispatch(kyc?.actions?.cleanKycState());
          setLoading(false);
          setShow(true);
        } else {
          // Handle the case when the response or response.data is undefined
          setAlert({
            alertMessage: 'Failed to submit details',
            alertMessageType: 'error',
          });
          setLoading(false);
        }
      } catch (error) {
        setAlert({
          alertMessage: error.response.data.message,
          alertMessageType: 'error',
        });
        setLoading(false);
      }
    },
  });

  const handleHide = () => {
    setShow(false);
    history.push('/');
  };

  return (
    <>
      <form className="mt-5" id="kt_form" onSubmit={formik.handleSubmit}>
        <KycPageContentWrapper>
          {alert?.alertMessage && <Alert alert={alert} />}
          <small className="font-weight-bold text-danger justify-content-center d-flex">
            {error ? error : ''}
          </small>
          {investor?.kycLevel?.level > 1 ? (
            <div className="d-flex justify-content-between flex-wrap">
              {investor?.kycLevel?.level === 2 ||
              (investor?.kycLevel?.level === 3 && !investor?.taxPayerNumber) ? (
                <CustomInput
                  type="text"
                  title={
                    investor?.country === 'KE' ? (
                      <>
                        <span className="font-weight-600">KRA</span>
                        <span> PIN Number</span>
                      </>
                    ) : (
                      <span> Tax Payer Number</span>
                    )
                  }
                  name="taxPayerNumber"
                  formik={formik}
                  getInputClasses={getInputClasses}
                  required={true}
                />
              ) : null}

              {investor?.country === countryCode.KE &&
              (investor?.kycLevel?.level === 2 ||
                (investor?.kycLevel?.level === 3 &&
                  !investor?.taxPinCertificate)) ? (
                <div className="upload-section center-input mt-7">
                  <FileUpload
                    titleStart="Upload "
                    title="Tax PIN Certificate"
                    name="taxPinCertificate"
                    handleChange={handleCardUpload}
                    displayText={
                      selectedTaxPinCertificate || investor?.taxPinCertificate
                        ? 'Change File'
                        : 'Upload File'
                    }
                    required={true}
                    loading={
                      uploading?.identificationFrontSide ||
                      uploading.proofOfAddress ||
                      uploading?.facePhoto ||
                      uploading?.taxPinCertificate
                    }
                  />
                  <UploadedFile
                    imageUrl={investor?.taxPinCertificate}
                    kycSubmittedAt={investor?.kycSubmittedAt}
                    loading={uploading.taxPinCertificate}
                    fileLabel={'Tax Pin Certificate'}
                    handleOpeningFile={handleOpeningFile}
                    name={'taxPinCertificate'}
                    uploadedFile={selectedTaxPinCertificateImageDataUrl}
                  />
                </div>
              ) : null}
            </div>
          ) : null}

          {investor?.kycLevel?.level === 3 ? (
            <div className="d-flex justify-content-between flex-wrap mt-10">
              <div className="upload-section center-input">
                <FileUpload
                  titleStart="Upload "
                  title="Self-Declaration Proof of Address"
                  name="proofOfAddress"
                  handleChange={handleCardUpload}
                  displayText={
                    selectedProofOfAddress || investor?.proofOfAddress
                      ? 'Change File'
                      : 'Upload File'
                  }
                  required={true}
                />

                <UploadedFile
                  imageUrl={investor?.proofOfAddress}
                  kycSubmittedAt={investor.kycSubmittedAt}
                  loading={uploading.proofOfAddress}
                  fileLabel={'Proof of Address'}
                  handleOpeningFile={handleOpeningFile}
                  name={'proofOfAddress'}
                  uploadedFile={selectedProofOfAddressImageDataUrl}
                />
              </div>

              <div
                className="width-345 center-input bg-light-blue-50 text-11 border-radius-3 font-weight-500 border-light-blue-400"
                style={{ height: '66px' }}
              >
                <div className="py-4 px-5">
                  Proof of address can be a utility bill, or lease agreement ,
                  or bill from telcos/banks.
                </div>
              </div>
            </div>
          ) : null}
          <ButtonsContainer justifyContent="justify-content-between">
            <CancelButton
              buttonText="Previous"
              handleOnClick={() => history.goBack()}
              twinButton
            />

            <ConfirmButton
              buttonText={'Save'}
              buttonType={'submit'}
              loading={loading}
              twinButton
            />
          </ButtonsContainer>
          <div className="text-center mt-5">
            <ValidationErrors
              errorMessage={
                taxPinCertificateError ||
                frontFileNameError ||
                proofOfAddressError ||
                facePhotoError
              }
            />{' '}
          </div>
          <Note
            classes={
              'mx-auto mt-10 mb-8 bg-orange-200 border-orange-600 p-3 border-radius-7 text-13'
            }
            title={'Note:'}
            content={'Please note this verification may take up to 48 hours.'}
            titleColor="text-red-200"
            contentColor="text-red-200"
            alignText="text-left"
          />
        </KycPageContentWrapper>
      </form>

      <CommonSuccessModal
        open={show}
        setOpen={() => setShow(false)}
        content={<KycCompletedModalContent profile={investor} />}
        handleButtonClick={handleHide}
        moreClasses={'bg-white'}
      />
    </>
  );
};

const mapStateToProps = ({ kyc, profile }) => ({
  kyc,
  profile,
});

export default injectIntl(
  connect(mapStateToProps, kyc.actions)(UploadDocuments),
);
