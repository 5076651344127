import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actions as investorProfileActions } from '../../Profile/_redux/profileRedux';

import { ConfirmButton, CustomButton } from '../../Common/ReusableButtons';
import { DoYouHaveAccount } from '../Components/DoYouHaveAccount';
import { handleGoogleSignUpAndSignIn } from '../../../helpers/googleAuth';
import Alert from '../../Common/Alert';
import { googleAuth } from '../../../helpers/constants';
import { cookiesDisabled } from '../helpers/cookiesDisabled';

export const SelectSignupOption = () => {
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [googleAuthLoading, setGoogleAuthLoading] = useState(false);
  const userProfileInformation = useSelector(state => state.profile);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleSignupWithGoogle = async () => {
    const cookies = cookiesDisabled();
    if (cookies.disabled) {
      setAlert({
        alertMessage: cookies?.errorMessage,
        alertMessageType: 'error',
      });
      return;
    }

    localStorage.setItem('googleSignInOrSignUp', googleAuth.GOOGLE_SIGN_UP);
    setGoogleAuthLoading(true);
    try {
      await handleGoogleSignUpAndSignIn();
      setGoogleAuthLoading(false);
    } catch (error) {
      setAlert({
        alertMessage: error?.message
          ? error?.message
          : 'Unable to sign up.Try again.',
        alertMessageType: 'error',
      });
    }
  };

  const signupWithEmail = () => {
    localStorage.removeItem('googleSignInOrSignUp');
    dispatch(
      investorProfileActions.profileAction({
        user: {},
        investor: {},
      }),
    );
    history.push('/auth/registration');
  };

  return (
    <div className="login-form login-signin">
      <div className="mb-5">
        {alert?.alertMessage ? <Alert alert={alert} /> : null}
      </div>
      <div className="d-flex flex-wrap justify-content-between align-items-center mobile-row">
        <ConfirmButton
          buttonText="Sign up with email"
          buttonWidth={'w-100'}
          changeText={false}
          handleOnClick={signupWithEmail}
        />
      </div>

      <div className="w-100 text-14 font-weight-500 text-center my-5 ">OR</div>

      <div className="form-group d-flex flex-wrap justify-content-between align-items-center mobile-row">
        <CustomButton
          buttonText="Sign up with Google"
          buttonWidth={'w-100'}
          changeText={false}
          classes={'border-1-gray-350 bg-gray-450'}
          addBorder
          handleOnClick={handleSignupWithGoogle}
          buttonIcon={'/media/logos/google.svg'}
          loading={googleAuthLoading}
          spinnerColor="spinner-black"
        />
      </div>

      <DoYouHaveAccount
        question="Already have an account?"
        action={'Sign in '}
        link={'/auth/login'}
        margin="mt-n3"
      />
    </div>
  );
};
