import React from 'react';
import Back from '../../Common/BackArrow';
import { bankTexts } from '../text';
import SingleDetail from '../components/SingleDetail';
import { AnimatedPageContainer } from '../../Common/AnimatedPageContainer';

const WithdrawalDetailsPage = () => {
  const previousPage = localStorage.getItem('previousPage');

  return (
    <AnimatedPageContainer>
      <div className="mt-5">
        <Back title="Withdrawal Details" previousPageLink={previousPage} />
        <div className="border-light-blue-600 px-md-10 p-5 border-radius-8">
          {bankTexts.map(text => (
            <SingleDetail key={text.id} {...text} />
          ))}
        </div>
      </div>
    </AnimatedPageContainer>
  );
};

export default WithdrawalDetailsPage;
