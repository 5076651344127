import React from 'react';

import Icon from '../../Common/PopoverIcon';
import { messages } from '../../../utils/messages';

export const PlanOrGoalInvestmentDetails = ({
  value,
  label,
  hasPendingTransactions,
  addInformationIcon,
}) => {
  return (
    <>
      <div className="my-4 font-weight-bold d-flex justify-content-between align-items-center text-13">
        <div className="mt-2">{label}</div>
        <div className="text-blue font-weight-500">
          {value}

          {hasPendingTransactions && addInformationIcon && (
            <Icon message={messages.pendingTransactions.currentValue} />
          )}
        </div>
      </div>
      <div className="my-2 blue-separator" />
    </>
  );
};
