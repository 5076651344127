import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import {
  InvestmentAction,
  investmentType,
} from '../../../../helpers/constants';
import { actions as investmentReducerActions } from '../../../Invest/_redux/investmentReducers';
import { actions as goalReducerActions } from '../../_redux/goalReducers';

import './index.scss';
import { trackEventMixpanel } from '../../../../eventTracking/mixpanel';

export const GoalCard = ({ goals }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const reduxGoalInvestmentDtls = useSelector(
    state => state?.goal?.goalInvestmentDetails,
  );

  const selectGoal = value => {
    trackEventMixpanel(`Select_Goal_${value?.name}`);
    if (value.goalNameIsRequired) {
      dispatch(
        investmentReducerActions.updateInvestmentAction(
          InvestmentAction.GOAL_INVESTMENT,
        ),
      );

      dispatch(
        goalReducerActions.updateGoalInvestmentDetails({
          ...reduxGoalInvestmentDtls,
          investmentType: investmentType.INITIAL_INVESTMENT,
          goalNameIsRequired: true,
          staticGoalId: value.id,
          goalName: '',
        }),
      );

      history.push('/goal/new');
    } else {
      localStorage.removeItem('subscriptionId');
      localStorage.removeItem('promoCode');
      localStorage.removeItem('goalDiscountInfo');

      dispatch(
        goalReducerActions.updateGoalInvestmentDetails({
          ...reduxGoalInvestmentDtls,
          investmentType: investmentType.INITIAL_INVESTMENT,
          goalName: value.name.replace('/', ' or '),
          staticGoalId: value.id,
          goalNameIsRequired: false,
        }),
      );

      history.push('/goal/calculator');
    }
  };

  return (
    <div className="goalCard">
      {goals?.map(goal => (
        <div
          className="goal-card border-radius-8 border-light-blue-545"
          onClick={() => selectGoal(goal)}
          key={goal.id}
        >
          <div className="side-left">
            <div className="goal-name">{goal.name}</div>
            <span className="goal-detail">{goal.description}</span>
          </div>
          <div className="side-right">
            <div style={{ width: '44px', height: '43px' }}>
              <img
                width={'100%'}
                height={'100%'}
                alt={goal.name}
                src={goal.iconLink}
              />
            </div>
            <div className="ml-2">
              <SVG src={'/media/svg/ndovu/arrow.svg'} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
