import React from 'react';
import { StepNumberOne } from '../../../Common/StepNumberOne';
import { StepNumberTwo } from '../../../Common/StepNumberTwo';
import { StepNumberThree } from '../../../Common/StepNumberThree';

export const InstructionStep = ({ text, stepNumber, noCenter, topMargin }) => {
  const steps = {
    1: <StepNumberOne backgroundColor={'#0071CE'} color={'#ffffff'} />,
    2: <StepNumberTwo backgroundColor={'#0071CE'} color={'#ffffff'} />,
    3: <StepNumberThree backgroundColor={'#0071CE'} color={'#ffffff'} />,
  };
  return (
    <div
      className={`d-flex ${topMargin ? topMargin : 'mt-3'} ${
        noCenter ? '' : 'align-items-center'
      }`}
    >
      {steps[stepNumber]}
      <div className="ml-2 text-12">{text}</div>
    </div>
  );
};
