/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import '@fortawesome/fontawesome-free/css/all.min.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';

import 'core-js/actual';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-datepicker/dist/react-datepicker.css';

import TagManager from 'react-gtm-module';

import 'socicon/css/socicon.css';
import './_metronic/_assets/plugins/flaticon/flaticon.css';
import './_metronic/_assets/plugins/flaticon2/flaticon.css';
import './_metronic/_assets/plugins/keenthemes-icons/font/ki.css';
import { MetronicI18nProvider } from './_metronic/i18n';
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider,
} from './_metronic/layout';
import App from './app/App';
import './index.scss'; // Standard version
import * as _redux from './redux';
import store, { persistedStore } from './redux/store';
import './sentry-configurations';
import * as serviceWorker from './serviceWorker';
import './styles.scss';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
};

TagManager.initialize(tagManagerArgs);
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
// /* const mock = */ _redux.mockAxios(axios);

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);

const applicationRoot = document.getElementById('root');
const root = createRoot(applicationRoot);
root.render(
  <MetronicI18nProvider>
    <MetronicLayoutProvider>
      <MetronicSubheaderProvider>
        <MetronicSplashScreenProvider>
          <App
            store={store}
            persistedStore={persistedStore}
            basename={PUBLIC_URL}
          />
        </MetronicSplashScreenProvider>
      </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
  </MetronicI18nProvider>,
);

serviceWorker.register();
