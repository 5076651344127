export const addCommas = (amount, roundAmount = true) => {
  const amountWithThousandsSeparator = amount
    ? amount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : 0;

  let parts =
    amountWithThousandsSeparator && amountWithThousandsSeparator?.split('.');

  if (parts.length === 2) {
    parts[1] = roundAmount
      ? parts[1]?.replace(/,/g, '')?.substring(0, 2)
      : parts[1]?.replace(/,/g, '');
    return parts?.join('.');
  }

  return amountWithThousandsSeparator;
};

export const removeNonNumeric = value => {
  let amount;

  amount = value?.toString()?.replace(/[^0-9.]/g, '');

  if (
    amount.length > 1 &&
    amount.charAt(0) === '0' &&
    value.charAt(1) !== '.'
  ) {
    amount = amount.slice(1);
  }
  return amount;
};

export const hasOnlyOneDot = amount => {
  const dots = amount
    ?.toString()
    ?.split('')
    ?.filter(char => char === '.');
  return dots?.length > 1 ? false : true;
};
