import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getUserInformationFromGoogle } from '../../../helpers/googleAuth';
import { loginWithGoogle } from '../_redux/authCrud';
import { updateLoginState } from '../helpers/udpateLoginState';
import { connect, useDispatch, useSelector } from 'react-redux';

import * as auth from '../_redux/authRedux';
import * as profile from '../../Profile/_redux/profileRedux';
import { actions as investorProfileActions } from '../../Profile/_redux/profileRedux';

import { handleRedirectionAfterLogin } from '../helpers/handleRedirectionAfterLogin';
import Loader from '../../Common/Loader';
import Alert from '../../Common/Alert';
import { ConfirmButton } from '../../Common/ReusableButtons';
import { googleAuth, googleAuthGenericError } from '../../../helpers/constants';

const GoogleCallback = props => {
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [loading, setLoading] = useState(true);
  const userProfileInformation = useSelector(state => state.profile);

  const history = useHistory();
  const dispatch = useDispatch();
  const googleSignInOrSignUp = localStorage.getItem('googleSignInOrSignUp');
  const signingUpWithGoogle =
    googleSignInOrSignUp === googleAuth.GOOGLE_SIGN_UP;

  const loginUser = async userData => {
    try {
      //Login with google
      const { data } = await loginWithGoogle(userData?.email);

      const { accessToken, user } = data;
      const investorDetails = user?.investors[0];

      updateLoginState(data, props.login, props.profileAction, profile);

      // Save ndovu token in the Local storage and remove idToken from Google
      localStorage.setItem('ndovuToken', accessToken);
      localStorage.removeItem('idToken');
      localStorage.removeItem('googleSignInOrSignUp');

      handleRedirectionAfterLogin(
        investorDetails?.isReferralCompleted,
        history,
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAlert({
        alertMessage: error?.response?.data
          ? error.response.data.message
          : 'Unable to login.Try again.',
        code: error?.response?.data?.code,
        alertMessageType: 'error',
      });
    }
  };

  const redirectToRegistrationPage = userData => {
    dispatch(
      investorProfileActions.profileAction({
        ...userProfileInformation,
        user: {
          ...userProfileInformation?.user,
          ...userData,
          hasSignedInWithGoogle: true,
        },
      }),
    );

    localStorage.removeItem('googleSignInOrSignUp');

    history.push('/auth/registration');
  };

  useEffect(() => {
    // Extract the token from the URL hash
    const hashParams = new URLSearchParams(window.location.hash.slice(1));
    const idToken = hashParams.get('id_token');

    if (idToken) {
      getUserInformationFromGoogle()
        .then(response => {
          if (response.success) {
            localStorage.setItem('idToken', response?.idToken);
            const userInformation = response?.data;

            signingUpWithGoogle
              ? redirectToRegistrationPage(userInformation)
              : loginUser(userInformation);
          } else {
            setAlert({
              alertMessage: response?.error,
              alertMessageType: 'error',
            });
            setLoading(false);
          }
        })
        .catch(error => {
          setAlert({
            alertMessage: googleAuthGenericError,
            alertMessageType: 'error',
          });
          setLoading(false);
        });
    } else {
      setAlert({
        alertMessage: googleAuthGenericError,
        alertMessageType: 'error',
      });
      setLoading(false);
    }
  }, []);

  const getButtonDetails = () => {
    let buttonDetails;
    if (alert?.code === 'USER_NOT_REGISTERED') {
      buttonDetails = { name: 'Sign up', link: '/auth/select-sign-up-option' };
    } else if (signingUpWithGoogle) {
      buttonDetails = {
        name: 'Back to sign up',
        link: '/auth/select-sign-up-option',
      };
    } else {
      buttonDetails = {
        name: 'Back to sign in',
        link: '/auth/login',
      };
    }

    return buttonDetails;
  };

  return (
    <div className="login-form login-signin">
      {loading ? (
        <Loader />
      ) : alert?.alertMessage ? (
        <div className="text-center">
          <Alert alert={alert} />
          <ConfirmButton
            buttonText={getButtonDetails()?.name}
            buttonWidth={'width-140 mt-5'}
            handleOnClick={() => history.push(getButtonDetails()?.link)}
            changeText={false}
          />
        </div>
      ) : null}
    </div>
  );
};

export default connect(null, { ...auth.actions, ...profile.actions })(
  GoogleCallback,
);
