import React from 'react';

import './MainWrapper.scss';

const MainWrapper = ({ children }) => {
  return (
    <div className="main-wrapper border-radius-8 border-light-blue-545">
      {children}
    </div>
  );
};

export default MainWrapper;
