import React from 'react';

const HorizontalLine = ({ color, width, height }) => {
  return (
    <hr
      className="my-3 mx-auto goal-summary-item-separator"
      style={{
        color,
        backgroundColor: color,
        height: height,
        width: width,
        borderColor: color,
      }}
    />
  );
};

export default HorizontalLine;
