import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import HistogramChart from '../components/HistogramChart';

import { actions as goalReducerActions } from '../../Goal/_redux/goalReducers';
import { actions as investmentReducerActions } from '../../Invest/_redux/investmentReducers';

import { actions as pocketActions } from '../../Pocket/_redux/reducers';
import {
  addToWatchList,
  removeFromWatchList,
} from '../../WatchList/_redux/watchListCrud';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { checkInvestorPermission } from '../../../helpers/checInvestorPermission';
import {
  FundType,
  InvestmentAction,
  customerIOEventType,
  investmentType,
} from '../../../helpers/constants';
import { handleAlert } from '../../../helpers/handleSuccessNotification';
import { firstLetterUpperCase } from '../../../helpers/investmentHelpers';

import investBtnIcon from '../../../icons/investBtnIcon.svg';
import { investmentsCopy } from '../../../utils/investmentsCopy';
import { messages } from '../../../utils/messages';
import Back from '../../Common/BackArrow';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import { CommonSuccessModal } from '../../Common/CommonSuccessModal';
import ErrorCard from '../../Common/ErrorCard';
import FundCard from '../../Common/FundCard';
import MessageAlert from '../../Common/MessageAlert';
import { PermissionModalContent } from '../../Common/PermissionModalContent';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import TextModal from '../../Invest/components/LearnMore';
import { actions as planReduxActions } from '../_redux/planReducers';
import { fetchGraphData, fetchSinglePlanDetails } from '../_redux/plansActions';
import AddToWatchlist from '../components/AddToWatchlist';
import { BongaNaNdovuFAQs } from '../components/BongaNaNdovuFAQs';
import CompanyBrand from '../components/CompanyBrand';
import Documents from '../components/Documents';
import HistoricalPerformance from '../components/HistoricalPerformance';
import { PlanDetailsCard } from '../components/PlanDetailsCard';
import { PlanDetailsParagraphs } from '../components/PlanDetailsParagraphs';
import SavingAccountMessage from '../components/SavingAccoutMessage';
import { eventTracking } from '../../../eventTracking/httpRequest';
import { trackEventMixpanel } from '../../../eventTracking/mixpanel';

const PlanDetails = () => {
  const dispatch = useDispatch();
  const planDetails = useSelector(state => state.plan.viewedPlan);
  const { investor, user } = useSelector(state => state?.profile);
  const { kycLevel, pendingKyc, originPartner } = investor;

  const history = useHistory();
  const { id: planId } = useParams();
  const [loading, setLoading] = useState(false);
  const [alertOn, setAlertOn] = useState(false);
  const [graphData, setGraphData] = useState({});
  const [dataForBarChartAvailable, setDataForBarChartAvailable] = useState();
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const pocketFlow = useSelector(state => state.pocketFlow);

  const { pathname } = history.location;

  const [withdrawalError, setWithdrawalError] = useState(null);
  const [showWithdrawalError, setShowWithdrawalError] = useState(false);

  const [errorMessage, setErrorMessage] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openTermConditionModal, setOpenTermConditionModal] = useState(false);
  const {
    isInvested,
    brands,
    factSheet,
    prospectus,
    about,
    investable,
    fundType,
    allowBongaPointPayment,
    termsAndConditionsRequired,
    nonInvestableNote,
    isFractionalStock,
    lockInPeriod,
  } = planDetails;

  const [show, setShow] = useState(false);

  const closeAlert = () => {
    setAlertOn(false);
  };

  const addToTheWatchList = async () => {
    trackEventMixpanel('Add_To_Watchlist_Click');
    try {
      setLoading(true);
      let res = await addToWatchList(parseInt(planId));
      if (res.status === 201) {
        fetchPlanDetails();
        handleAlert(res.data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage: error.response.data.error,
        alertMessageType: 'alert-light-danger',
      });
    }
  };

  const fetchPlanDetails = async () => {
    try {
      setLoading(true);
      const res = await fetchSinglePlanDetails(parseInt(planId));
      if (res.status === 200) {
        const plan = res.data.data;
        dispatch(planReduxActions.viewedPlan(plan));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMessage(error?.response?.data?.message);
    }
  };

  const handleHide = () => {
    setShow(false);
    if (kycLevel?.level === 1 && pendingKyc.length >= 6) {
      history.push('/kyc-information');
    } else if (kycLevel?.level === 2 && pendingKyc?.length >= 2) {
      history.push('/manage-account/investor-info/personal-address');
    } else if (kycLevel?.level === 3 && pendingKyc?.length >= 1) {
      history.push('/manage-account/investor-info/personal-address');
    } else {
      history.push('/manage-account/information');
    }
  };

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const termsAndConditionsModal = (
    <div>
      <div>
        Before proceeding to invest in this fund please review and accept the
        terms and conditions for on-boarding on to our private wealth product.
      </div>
      <div className="icon bg-white d-inline-block px-5 py-1 border-radius-3 mt-3 cursor-pointer">
        <div className="d-flex justify-content-center align-items-center">
          <SVG src={toAbsoluteUrl('/media/svg/ndovu/link.svg')} />
          <span
            className="pl-3 text-blue"
            onClick={() => openInNewTab(planDetails?.termsAndConditions)}
          >
            Read the terms & Conditions
          </span>
        </div>
      </div>
    </div>
  );

  const getGraphData = async () => {
    try {
      const res = await fetchGraphData(planId);
      if (res.status === 200) {
        const plan = res.data.data;

        const dataForBarChart = [
          {
            name: 'Last Year',
            value: plan.performance1 ? plan.performance1 : 0,
          },
          {
            name: '3 Years Ago',
            value: plan.performance3 ? plan.performance3 : 0,
          },
          {
            name: '5 Years Ago',
            value: plan.performance5 ? plan.performance5 : 0,
          },
          {
            name: '10 Years Ago',
            value: plan.performance10 ? plan.performance10 : 0,
          },
        ];
        setDataForBarChartAvailable(
          dataForBarChart?.some(data => data.value > 0 || data.value < 0),
        );
        setGraphData(dataForBarChart);
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchPlanDetails();

    getGraphData();
    resetPlanInvestmentDtls();
    dispatch(goalReducerActions.ResetGoalInvestmentDetails());
    localStorage.removeItem('termsAndConditions');
  }, []);

  const resetPlanInvestmentDtls = () => {
    dispatch(planReduxActions.ResetPlanInvestmentDetails());
  };

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  const deleteWatchlist = async id => {
    try {
      setLoading(true);
      const res = await removeFromWatchList(id);
      fetchPlanDetails();
      handleAlert(res.data.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage: error.response.data.error,
        alertMessageType: 'alert-light-danger',
      });
    }
  };

  const generateBackButtonText = () => {
    let buttonText = '';
    if (pathname.includes('watchlist')) {
      buttonText = 'Back to Watchlist';
    } else if (pathname.includes('fixed-return')) {
      buttonText = 'Back to Fixed Returns';
    } else {
      buttonText = 'Back';
    }

    return buttonText;
  };

  const generateButtonText = () => {
    let buttonText = '';
    if (investable) {
      if (isInvested) {
        buttonText = 'Top Up';
      } else if (fundType === FundType.SAVE) {
        buttonText = 'Save Now';
      } else if (allowBongaPointPayment) {
        buttonText = 'Convert';
      } else {
        buttonText = 'Invest Now';
      }
    } else {
      buttonText = 'Not Investable';
    }

    return buttonText;
  };

  const handleInvest = async buttonName => {
    const investorHasPermissionToInvest = checkInvestorPermission(
      user?.businessChamaRole?.name,
      investor?.investorType,
      'investment',
    );

    if (!investorHasPermissionToInvest) {
      setShow(true);
      return;
    }

    isInvested &&
      trackEventMixpanel(`Topup_${planDetails?.coolName}_${buttonName}`);

    try {
      await eventTracking(customerIOEventType.INVEST_ON_PRODUCT_PAGE);
    } catch (error) {}

    // Set that terms and conditions are accepted if required
    if (termsAndConditionsRequired && !isInvested) {
      localStorage.setItem(
        'termsAndConditions',
        JSON.stringify({
          termsAndConditionsAccepted: true,
          id: isInvested?.id,
        }),
      );
    }

    if (withdrawalError) {
      return setShowWithdrawalError(true);
    }

    localStorage.removeItem('promoCode');
    localStorage.removeItem('discountInfo');
    localStorage.removeItem('subscriptionId');
    localStorage.removeItem('goalDiscountInfo');

    localStorage.setItem('pathName', pathname);
    localStorage.setItem('investingPageEntryPoint', pathname);

    dispatch(
      pocketActions?.updatePocketFlow({
        ...pocketFlow,
        type: { label: 'Invest', path: 'investment' },
        investmentType: isInvested
          ? investmentType.TOP_UP
          : investmentType.INITIAL_INVESTMENT,
      }),
    );

    dispatch(
      investmentReducerActions.updateInvestmentAction(
        isInvested
          ? InvestmentAction.DIRECT_INVESTMENT_TOPUP
          : InvestmentAction.DIRECT_INVESTMENT,
      ),
    );

    const redirectUrl = isInvested
      ? `/plans/topup/${isInvested?.id}`
      : `/plans/initialInvestmentAmount/${planId}`;

    history.push(redirectUrl, { backUrl: pathname });
  };

  const disableInvestButton = !investable;
  const previousPage = localStorage.getItem('planEntryPointUrl');

  const openTermsAndConditionModal = () => {
    setOpenTermConditionModal(true);
  };

  return (
    <>
      {errorMessage ? (
        <ErrorCard errorMessage={errorMessage} />
      ) : (
        <>
          {!originPartner && (
            <Back
              text={generateBackButtonText()}
              previousPageLink={previousPage}
            />
          )}

          {alertOn ? (
            <MessageAlert closeAlert={closeAlert} alert={alert} />
          ) : null}

          <FundCard
            planDetails={planDetails}
            investBtnIcon={investBtnIcon}
            generateButtonText={generateButtonText}
            handleInvestButtonClick={() =>
              termsAndConditionsRequired && !isInvested
                ? openTermsAndConditionModal()
                : handleInvest('Button_1')
            }
            termsAndConditionsRequired={termsAndConditionsRequired}
            openTermsAndConditionModal={openTermsAndConditionModal}
          />
          {lockInPeriod ? (
            <SavingAccountMessage
              message={messages.savingFundAlert}
              lockInPeriod={lockInPeriod}
              classes="mt-5 mb-2"
            />
          ) : null}

          <PlanDetailsCard
            title={
              isFractionalStock
                ? 'About this Company'
                : investmentsCopy?.fund?.action
            }
          >
            <div className="p-5 mt-5 text-center d-flex flex-column">
              <PlanDetailsParagraphs
                content={
                  <>
                    {about}{' '}
                    {allowBongaPointPayment ? (
                      <span>
                        Please read the FAQs{' '}
                        <span
                          className="plan-cool-name cursor-pointer"
                          onClick={() => setOpenModal(true)}
                        >
                          here
                        </span>{' '}
                        before proceeding.
                      </span>
                    ) : null}
                  </>
                }
              />
              <br />
              {showWithdrawalError && (
                <MessageAlert
                  closeAlert={() => setShowWithdrawalError(false)}
                  alert={withdrawalError}
                />
              )}

              {!investable && nonInvestableNote ? (
                <div className="fund-description center-container">
                  <strong>Note: </strong>
                  {nonInvestableNote}
                </div>
              ) : null}
            </div>
          </PlanDetailsCard>

          {brands && brands.length > 0 ? (
            <PlanDetailsCard title={investmentsCopy.companies.tilte}>
              <div className="p-5 my-5 text-center d-flex flex-column">
                <PlanDetailsParagraphs
                  content={
                    brands && brands?.length > 1
                      ? investmentsCopy.companies.description
                      : null
                  }
                />

                <div className="flex-wrap mt-5 companies-container justify-content-center">
                  {brands.map(brand => (
                    <CompanyBrand
                      key={brand?.id}
                      companyName={firstLetterUpperCase(brand?.name)}
                      brandLogo={brand?.brandLogo}
                    />
                  ))}
                </div>
              </div>
            </PlanDetailsCard>
          ) : null}

          {!planDetails?.allowBongaPointPayment && dataForBarChartAvailable ? (
            <HistoricalPerformance plan={planDetails}>
              <HistogramChart graphData={graphData} plan={planDetails} />
            </HistoricalPerformance>
          ) : null}

          <AddToWatchlist
            deleteWatchlist={deleteWatchlist}
            addToTheWatchList={addToTheWatchList}
            withdrawalError={withdrawalError}
            planDetails={planDetails}
            pathName={pathname}
            isInvested={isInvested}
            generateButtonText={generateButtonText}
            handleInvest={handleInvest}
            triggerTCModal={setOpenTermConditionModal}
            termsAndConditions={termsAndConditionsRequired}
            disableInvestButton={disableInvestButton}
            termsAndConditionsRequired={termsAndConditionsRequired}
            openTermsAndConditionModal={openTermsAndConditionModal}
          />

          {prospectus || factSheet ? (
            <Documents
              prospectus={prospectus}
              factSheet={factSheet}
              isFractionalStock={isFractionalStock}
            />
          ) : null}

          <TextModal
            open={openModal}
            setOpen={setOpenModal}
            text={<BongaNaNdovuFAQs />}
            title="BONGA NA NDOVU FAQs."
          />

          <CommonSuccessModal
            open={openTermConditionModal}
            setOpen={() => setOpenTermConditionModal(false)}
            content={termsAndConditionsModal}
            moreClasses={'bg-light-blue-100'}
            iconName={'termsandCondition'}
            title={`${planDetails?.coolName} Terms & Conditions`}
            buttons={
              <ButtonsContainer justifyContent="justify-content-between">
                <CancelButton
                  buttonText={'Cancel'}
                  handleOnClick={() => {
                    setOpenTermConditionModal(false);
                  }}
                  twinButton
                />

                <ConfirmButton
                  buttonText={'Accept'}
                  loading={loading}
                  handleOnClick={() => handleInvest()}
                  twinButton
                />
              </ButtonsContainer>
            }
          />
        </>
      )}

      <CommonSuccessModal
        open={show}
        setOpen={() => setShow(false)}
        content={<PermissionModalContent />}
        handleButtonClick={() => setShow(false)}
        moreClasses={'bg-white'}
        title={'Notification'}
        addCloseButton={true}
        onClose={() => handleHide()}
      />
    </>
  );
};

export default PlanDetails;
