import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { CancelButton, ConfirmButton } from './ReusableButtons';
import { ButtonsContainer } from './ButtonsContainer';
import { IconButton } from '@material-ui/core';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '560px',
    boxShadow: theme.shadows[5],
    background: '#FFFFFF',
    [theme.breakpoints.down(868)]: {
      width: '90%',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    background: '#F7F7F7',
    padding: '1rem',
    borderRadius: '8px',
  },
  closeButton: {
    padding: 0,
  },
  cancelTitle: {
    width: '191px',
    height: '17px',
    top: '16px',
    left: '24px',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '16.8px',
    textAlign: 'left',
  },
}));

export const CancelRecurringPaymentModal = ({
  open,
  setOpen,
  content,
  moreClasses,
  handleButtonClick,
  addCloseButton,
  title,
  handleCancelButton,
  iconName,
}) => {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();

  const body = (
    <div
      style={modalStyle}
      className={`${classes.paper} ${moreClasses}  border-radius-8`}
    >
      <div className={classes.header}>
        <span className={classes.cancelTitle}>Cancel Recurring Payment</span>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={setOpen}
        >
          <CloseIcon />
        </IconButton>
      </div>

      <div className="p-5">
        <div className="d-flex align-items-center justify-content-center">
          <div>
            <div className="w-100 text-18 text-blue font-weight-600 text-center">
              We are sad to see you go!
            </div>
            <div className="text-10 text-center">
              Are you sure you want to cancel your recurring payment?
            </div>
          </div>
        </div>

        <ButtonsContainer justifyContent="justify-content-between mt-10">
          <CancelButton
            buttonText="Cancel"
            handleOnClick={handleCancelButton}
            twinButton
          />
          <ConfirmButton
            buttonText={'Proceed'}
            handleOnClick={handleButtonClick}
            twinButton
          />
        </ButtonsContainer>
      </div>
    </div>
  );
  return (
    <Modal
      open={open}
      onClose={() => addCloseButton && setOpen()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};
