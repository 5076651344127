export const cookiesDisabled = () => {
  let cookieEnabled = navigator.cookieEnabled;

  if (!cookieEnabled) {
    // Set cookie
    document.cookie = 'cookie_check=enabled';

    // Check if the cookie was set
    cookieEnabled = document.cookie.indexOf('cookie_check=enabled') !== -1;

    if (!cookieEnabled) {
      return {
        disabled: true,
        errorMessage:
          'Cookies are disabled in your browser. Please enable cookies to continue with Google.',
      };
    }
  }
  return { disabled: false };
};
