import dotenv from 'dotenv';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { actions as pocketActions } from '../../Pocket/_redux/reducers';
import { actions as goalReducerActions } from '../_redux/goalReducers';

import InputValidationErrorMessage from '../../Common/InputValidationErrorMessage';
import SliderInput from '../components/GoalCalculator/SliderInput';
import convertAmountToNumber from '../../../helpers/convertAmountToNumber';
import '../index.scss';
import { moneyFormat } from '../../../helpers/moneyFormat';
import TargetAmountAndCurrency from '../components/GoalCalculator/TargetAmountAndCurrency';
import NextButton from '../components/GoalCalculator/NextButton';
import { findCurrency } from '../../../helpers/findCurreny';
import Back from '../../Common/BackArrow';
import { Currency } from '../../../helpers/constants';
import { getPocketDetails } from '../../Pocket/_redux/axiosRequest';
import Alert from '../../Common/Alert';
import { GoalCalculatorInputContainer } from '../components/GoalCalculatorInputContainer';
import { AnimatedPageContainer } from '../../Common/AnimatedPageContainer';

dotenv.config();

const maxYears = 12;
const numbersRegex = /^[0-9,]*$/;

const GoalCalculator = props => {
  const {
    profile: {
      investor: { riskProfile, currency },
    },
    currencies: { currencies: allCurrencies },
  } = useSelector(state => state);

  const [goalPayload, setGoalPayload] = useState({
    currency: currency?.currency || Currency.KES,
    years: 0,
    months: 0,
    riskLevel: riskProfile?.id,
    goalAmount: 0,
  });

  const [months, setMonths] = useState(0);
  const [years, setYears] = useState(3);
  const [targetDateError, setTargetDateError] = useState(null);
  const [backendError, setBackendError] = useState(null);
  const [validationError, setValidationError] = useState();

  const pockets = useSelector(state => state.pockets);
  const reduxGoalInvestmentDtls = useSelector(
    state => state?.goal?.goalInvestmentDetails,
  );

  const dispatch = useDispatch();

  const { intl } = props;
  let history = useHistory();

  const initialValues = {
    initialAmount: reduxGoalInvestmentDtls?.initialAmount,
    goalAmount: reduxGoalInvestmentDtls?.monthlyAmount,
    currency: reduxGoalInvestmentDtls?.currency,
    riskLevel: reduxGoalInvestmentDtls?.id,
  };

  const fetchPocketDetails = async pocketId => {
    if (pocketId) {
      try {
        const response = await getPocketDetails(pocketId);
        dispatch(
          pocketActions.updatePocket({
            ...response?.data,
            currency: response?.data?.currency?.currency,
          }),
        );
      } catch (error) {
        setBackendError({
          alertMessageType: 'error',
          alertMessage: error?.response?.data?.message,
        });
      }
    } else {
      dispatch(pocketActions.updatePocket(null));
    }
  };

  useEffect(() => {
    !reduxGoalInvestmentDtls?.staticGoalId &&
      !reduxGoalInvestmentDtls?.goalName &&
      history.push('/goal/select-goal');

    reduxGoalInvestmentDtls?.years && setYears(reduxGoalInvestmentDtls?.years);
    reduxGoalInvestmentDtls?.months &&
      setMonths(reduxGoalInvestmentDtls?.months);

    setGoalPayload({
      ...goalPayload,
      initialAmount: reduxGoalInvestmentDtls?.initialAmount,
      goalAmount: reduxGoalInvestmentDtls?.monthlyAmount,
      years: reduxGoalInvestmentDtls?.years,
      months: reduxGoalInvestmentDtls?.months,
      currency:
        reduxGoalInvestmentDtls?.currency?.length > 0
          ? reduxGoalInvestmentDtls?.currency
          : currency?.currency,
    });

    const currencyWithPocket = pockets?.find(
      pocket =>
        pocket.currency === reduxGoalInvestmentDtls?.currency ||
        pocket.currency === currency?.currency,
    );

    fetchPocketDetails(currencyWithPocket?.id);
  }, [reduxGoalInvestmentDtls]);

  const GoalAmountSchema = Yup.object().shape({
    goalAmount: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      }),
    ),

    currency: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      }),
    ),
  });

  const handleYearChange = event => {
    const { value, id } = event.target;
    if (!numbersRegex.test(value)) {
      return false;
    }
    if (id === 'years') {
      if (value >= maxYears) {
        setYears(maxYears);
        setMonths(0);
      } else {
        setYears(Number(value));
      }
    }

    if (id === 'months') {
      if (years >= maxYears) {
        setMonths(0);
      } else if (value >= 12) {
        const numberOfYears = Number((value / 12).toString().split('.')[0]);
        const totalYears = Number(years) + numberOfYears;
        setYears(totalYears >= maxYears ? maxYears : totalYears);
        setMonths(Number(value % 12));
      } else {
        setMonths(Number(value));
      }
    }
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setValidationError({ ...validationError, [name]: null });
    const convertTonumber = convertAmountToNumber(value);

    if (!numbersRegex.test(value)) {
      return false;
    }

    if (name === 'targetDate') {
      setYears(
        Number(
          Number(convertTonumber / 12)
            .toString()
            .split('.')[0],
        ),
      );

      setMonths(Number(convertTonumber % 12));
      setTargetDateError(null);
    } else if (name === 'initialAmount' || name === 'goalAmount') {
      setGoalPayload({
        ...goalPayload,
        [name]: moneyFormat(convertAmountToNumber(value)),
      });

      const currencyDetails = findCurrency(
        allCurrencies,
        goalPayload?.currency,
      );

      if (convertTonumber < currencyDetails?.minimumInvestableAmount) {
        setValidationError({
          ...validationError,
          [name]: `The minimum amount is ${currencyDetails?.minimumInvestableAmount} ${currencyDetails?.currency}`,
        });
      }

      if (name === 'initialAmount') {
        if (convertTonumber > currencyDetails?.maximumInvestableAmount) {
          setValidationError({
            ...validationError,
            [name]: `The maximum amount is ${currencyDetails?.maximumInvestableAmount} ${currencyDetails?.currency}`,
          });
        }
      } else {
        if (convertTonumber > currencyDetails?.maximumRecurringAmount) {
          setValidationError({
            ...validationError,
            [name]: `The maximum amount is ${currencyDetails?.maximumRecurringAmount} ${currencyDetails?.currency}`,
          });
        }
      }
    } else {
      setGoalPayload({
        ...goalPayload,
        [name]: value,
      });
    }
  };

  const goalAmountNumber = goalPayload?.goalAmount
    ? convertAmountToNumber(goalPayload?.goalAmount)
    : 0;
  const initialAmountNumber = convertAmountToNumber(goalPayload?.initialAmount);

  const interestRate = riskProfile?.avgYTD / 100;

  // compound-interest-formula
  // Total = [ Compound interest for principal ] + [ Future value of a series ]
  // Total = [ P(1+r/n)^(nt) ] + [ PMT × (((1 + r/n)^(nt) - 1) / (r/n)) ]
  // https://www.thecalculatorsite.com/articles/finance/compound-interest-formula.php

  const totalMonths = years * 12 + months;
  const compoundInterestForPrincipal =
    initialAmountNumber * Math.pow(1 + interestRate / 12, totalMonths);

  const futureValueOfSeries =
    goalAmountNumber *
    ((Math.pow(1 + interestRate / 12, totalMonths) - 1) / (interestRate / 12));

  const totalTargetAmount = Number(
    compoundInterestForPrincipal + futureValueOfSeries,
  ).toFixed(2);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: GoalAmountSchema,
  });

  const handleSubmit = () => {
    if (years * 12 + months < 1) {
      setTargetDateError('Time is required');
      return;
    }

    dispatch(
      goalReducerActions.updateGoalInvestmentDetails({
        ...reduxGoalInvestmentDtls,
        years,
        months,
        monthlyAmount: goalPayload?.goalAmount,
        initialAmount: goalPayload?.initialAmount,
        targetAmount: moneyFormat(totalTargetAmount),
        currency: currency?.currency,
        riskLevel: goalPayload?.riskLevel,
        goalName: reduxGoalInvestmentDtls?.goalName,
        nameRequired: reduxGoalInvestmentDtls?.goalNameIsRequired,
      }),
    );

    history.push('/goal/summary');
  };

  const previousPage = localStorage.getItem('previousPage');

  return (
    <AnimatedPageContainer>
      <Back
        text={'Back'}
        title={'Goal Calculator'}
        onClick={() =>
          history.push(previousPage ? previousPage : '/goal/select-goal')
        }
      />
      {backendError ? (
        <Alert closeAlert={() => setBackendError(null)} alert={backendError} />
      ) : null}

      <form id="kt_form" onSubmit={formik.handleSubmit}>
        <TargetAmountAndCurrency
          compoundInterest={Number(totalTargetAmount)}
          currency={goalPayload?.currency || currency?.currency}
        />
        <div className="">
          <div className="w-100 d-flex justify-content-between flex-wrap">
            <GoalCalculatorInputContainer
              label={'How much are you depositing in your goal today?'}
            >
              <input
                type="text"
                className="border-gray-450 height-45 input-styles px-5 w-100 mt-13"
                name="initialAmount"
                value={goalPayload?.initialAmount || ''}
                onChange={handleChange}
                placeholder="Enter amount here"
                autoComplete="off"
                disabled={reduxGoalInvestmentDtls?.goalId}
              />

              <InputValidationErrorMessage
                message={validationError?.initialAmount}
              />
            </GoalCalculatorInputContainer>

            <GoalCalculatorInputContainer
              label={'How much will you deposit monthly in your goal?'}
            >
              <input
                type="text"
                className="border-gray-450 height-45 input-styles px-5 w-100 mt-13"
                name="goalAmount"
                value={goalPayload?.goalAmount || ''}
                onChange={handleChange}
                placeholder="Enter amount here"
                autoComplete="off"
                disabled={reduxGoalInvestmentDtls?.goalId}
              />

              <InputValidationErrorMessage
                message={validationError?.goalAmount}
              />
            </GoalCalculatorInputContainer>
          </div>

          <div className="w-100 d-flex justify-content-between flex-wrap">
            <div className="mt-5 input-card-container border-light-blue-545 border-radius-8">
              <SliderInput
                formik={formik}
                handleChange={handleChange}
                title="How long are you planning to save for?"
                rangeInputName="targetDate"
                sliderValue={years * 12 + months}
                silderLabels={
                  <div className="d-flex justify-content-between mx-auto goal-amount-slider-container mt-2 px-5">
                    <p className="text-11 font-weight-500">1 Month</p>
                    <p className="text-11 font-weight-500">{`${maxYears} Years`}</p>
                  </div>
                }
                min={0}
                max={maxYears * 12}
                step={6}
                disabled={reduxGoalInvestmentDtls?.goalId}
                input={
                  <>
                    <div className="d-flex mx-auto justify-content-between goal-amount-slider-container px-5 input-container mt-8">
                      <div className="d-flex align-items-center">
                        <input
                          type="text"
                          className="text-center bg-light-blue-60 border-light-blue-600 height-45 input-styles py-2"
                          style={{
                            width: '85px',
                          }}
                          name="targetDate"
                          id="years"
                          value={years.toString()}
                          onChange={handleYearChange}
                          autoComplete="off"
                          disabled={reduxGoalInvestmentDtls?.goalId}
                        />
                        <span className="ml-1 text-11 font-weight-500">
                          Years
                        </span>
                      </div>

                      <div className="d-flex align-items-center ml-3">
                        <input
                          type="text"
                          className="text-center bg-light-blue-60 border-light-blue-600 height-45 py-2 input-styles"
                          style={{
                            width: '85px',
                          }}
                          name="targetDate"
                          value={months.toString()}
                          id="months"
                          onChange={handleYearChange}
                          autoComplete="off"
                          disabled={reduxGoalInvestmentDtls?.goalId}
                        />
                        <span className="ml-1 text-11 font-weight-500">
                          Months
                        </span>
                      </div>
                    </div>

                    {targetDateError ? (
                      <InputValidationErrorMessage message={targetDateError} />
                    ) : null}
                  </>
                }
              />
            </div>

            <div
              className="mt-5 text-13 border-light-blue-545 border-radius-8 input-card-container"
              style={{
                textAlign: 'justify',
              }}
            >
              <div className="text-14 pt-3 pl-3 pr-3">
                <div className="font-weight-600">*Disclaimer:</div>
                <p className="text-dark-gray-300 mt-5">
                  This calculator is intended for use in making a rough estimate
                  of how your investments can grow over time. We do not
                  guarantee the accuracy of the results or their relevance to
                  your particular circumstances. It does not show the effect of
                  taxes on a taxable account.
                </p>
              </div>
            </div>
          </div>
        </div>

        <NextButton
          currency={findCurrency(
            allCurrencies,
            goalPayload?.currency ? goalPayload?.currency : currency?.currency,
          )}
          initialAmount={initialAmountNumber}
          goalAmount={goalAmountNumber}
          handleSubmit={handleSubmit}
        />
      </form>
    </AnimatedPageContainer>
  );
};

export default injectIntl(GoalCalculator);
