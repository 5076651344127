import React from 'react';

export const ApplyVoucherCode = ({ setUseVoucher }) => {
  return (
    <p className="text-13 mt-5">
      Do you have a bonus code?{' '}
      <span
        onClick={() => setUseVoucher(true)}
        className="cursor-pointer text-blue font-weight-600"
      >
        Apply code
      </span>
    </p>
  );
};
