export const bankTexts = [
  {
    id: 1,
    name: 'Mobile Money Details',
    linkTo: '/withdrawal-details/mobile-money',
    type: 'mobileMoney',
    iconName: 'mobile',
    eventName: 'Add_Mobile_Money_Details',
  },
  {
    id: 2,
    name: 'Banks  Details',
    linkTo: '/withdrawal-details/bank-details',
    type: 'bankDetails',
    iconName: 'bank',
    eventName: 'Add_Bank_Details',
  },
];

export const bankDetailsData = [
  { id: 1, bankName: 'Kenya Commercial Bank - KCB', accountCurrency: 'kes' },
  { id: 2, bankName: 'National Bank', accountCurrency: 'kes' },
  { id: 3, bankName: 'Bank of America', accountCurrency: 'usd' },
];

export const mobileMoneyData = [
  {
    id: 1,
    name: 'Safaricom M-Pesa',
    countryCode: '254',
    phoneNumber: '722 444 777',
  },
  {
    id: 2,
    name: 'Airtel Money',
    countryCode: '254',
    phoneNumber: '733 444 777',
  },
];
