import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { trackEventMixpanel } from '../../../eventTracking/mixpanel';

const LearnCard = ({ videos }) => {
  const [youtubeVideoId, setYoutubeVideoId] = useState('');
  const [show, setShow] = useState(false);

  let videoUrl =
    'https://www.youtube.com/embed/' +
    youtubeVideoId +
    '?autoplay=1;mode=opaque&amp;rel=0&amp;autohide=1&amp;showinfo=0&amp;wmode=transparent';

  const handleHide = () => setShow(false);

  const handleShow = videoLink => {
    trackEventMixpanel('Play_Video');
    setYoutubeVideoId(videoLink);
    setShow(true);
  };

  return (
    <div className="fund-plans">
      {videos?.items?.map(video => {
        return (
          <div className="d-flex flex-column pb-2" key={video.id}>
            <div
              className="learn-card"
              style={{
                backgroundImage: 'url(' + video.thumbnail + ')',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div className="top-right">
                <div className="learn-card-time">{video.length}</div>
              </div>

              <div
                className="centered-svg cursor-pointer"
                onClick={() => handleShow(video.youtubeVideoId)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="64"
                  height="63"
                  viewBox="0 0 64 63"
                  fill="none"
                >
                  <path
                    d="M31.8099 62.9031C49.1753 62.9031 63.2527 48.8257 63.2527 31.4603C63.2527 14.095 49.1753 0.0175781 31.8099 0.0175781C14.4446 0.0175781 0.367188 14.095 0.367188 31.4603C0.367188 48.8257 14.4446 62.9031 31.8099 62.9031Z"
                    fill="#0071CE"
                    fillOpacity="0.25"
                  />
                  <path
                    d="M42.1909 35.014C44.9277 33.4345 44.9283 29.4847 42.1921 27.9043L29.7041 20.6916C26.9676 19.111 23.5464 21.0859 23.5464 24.2461V38.6658C23.5464 41.8253 26.9664 43.8003 29.7029 42.221L42.1909 35.014Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            <span className="learn-card-title font-weight-500">
              {video.title}
            </span>
          </div>
        );
      })}

      <Modal
        show={show}
        onHide={handleHide}
        centered="true"
        className="learning-modal"
      >
        <iframe
          width="100%"
          height="100%"
          src={videoUrl}
          frameBorder="0"
          allow="autoplay"
          allowFullScreen
        ></iframe>
      </Modal>
    </div>
  );
};

export { LearnCard };
