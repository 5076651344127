import React from 'react';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

export const InvestorAccountType = ({ selectPrimaryAccount, account }) => {
  return (
    <div
      onClick={() => selectPrimaryAccount(account?.value)}
      className="card animated-card card-custom border-radius-3 border-1-light-blue-460 wizard-custom-selector gutter-b"
    >
      <div
        className="card-body d-flex flex-row justify-content-between align-items-center"
        style={{ padding: '10px 17px' }}
      >
        <div className="d-flex align-items-center">
          <div>
            <SVG
              src={toAbsoluteUrl(
                `/media/svg/ndovu/accountTypes/${account?.value?.toLowerCase()}.svg`,
              )}
            />
          </div>

          <div className=" d-flex flex-column ml-2">
            <div className="text-14 font-weight-500 text-blue">
              {account?.label}
            </div>
            <div className="text-11">{account?.description}</div>
          </div>
        </div>

        <div>
          <SVG
            src={toAbsoluteUrl('/media/svg/ndovu/arrow-right-bold.svg')}
          ></SVG>
        </div>
      </div>
    </div>
  );
};
