export const extractDateParts = date => {
  const nullValues = { year: null, month: null, day: null };
  if (!date) return nullValues;

  const parsedDate = new Date(date);

  if (isNaN(parsedDate.getTime())) {
    return nullValues;
  }

  const year = parsedDate.getUTCFullYear();
  const month = String(parsedDate.getUTCMonth() + 1).padStart(2, '0');
  const day = parsedDate.getUTCDate();

  return { year, month, day };
};
