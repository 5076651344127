import React, { useState } from 'react';
import * as Yup from 'yup';
import * as auth from '../_redux/authRedux';
import PasswordChecklist from 'react-password-checklist';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import ExternalPageTitle from '../../Common/ExternalPageTitle/ExternalPageTitle';
import { requestPasswordConfirmation } from '../_redux/authCrud';
import {
  passwordRegex,
  passwordValidationMessage,
} from '../../../helpers/constants';
import { PasswordInput } from '../../Common/PasswordInput';
import { ConfirmButton } from '../../Common/ReusableButtons';
import { DisplayErrorOrSuccessMessage } from '../Components/ErrorAndSuccessMessage';

const initialValues = {
  password: '',
  confirmPassword: '',
};

const ResetPassword = props => {
  let history = useHistory();
  const searchParams = new URLSearchParams(props.location.search);
  const passwordResetCode = searchParams.get('code');
  const id = Number(searchParams.get('id'));
  const { intl } = props;

  const [success, setSuccess] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const RedirectToLogin = () => {
    history.push('/auth/login');
  };

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .matches(passwordRegex, passwordValidationMessage)
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
    confirmPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      )
      .when('password', {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          "Password and Confirm Password didn't match",
        ),
      }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (!passwordResetCode?.length) {
        setSubmitting(false);
        errorMessage(
          'Password reset code is missing, Try requesting to reset the password again',
        );
        setSuccess(false);
        return;
      }

      try {
        const response = await requestPasswordConfirmation(
          id,
          values.password,
          passwordResetCode,
        );
        setSubmitting(false);

        if (response.status === 200) {
          setErrorMessage(null);
          setSuccess(true);
          setSuccessMessage(response.data.message);
          setTimeout(RedirectToLogin, 3000);
        }
      } catch (error) {
        setSuccessMessage(null);
        setSubmitting(false);
        setSuccess(false);
        setErrorMessage(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : 'Something went wrong with the password reset, Try opening the reset password link from the email we sent, or request password reset again',
        );
      }
    },
  });

  const { password } = formik.values;

  return (
    <>
      <div className="login-form login-forgot" style={{ display: 'block' }}>
        <ExternalPageTitle
          title="Reset Password"
          subTitle="Enter your new password"
        />
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          <DisplayErrorOrSuccessMessage
            success={success}
            successMessage={successMessage}
            errorMessage={errorMessage}
          />

          {/* begin: password */}
          <PasswordInput
            viewPassword={viewPassword}
            formik={formik}
            setViewPassword={setViewPassword}
            name={'password'}
            placeholder="Password"
          >
            <div className="mt-5">
              {formik.touched.password && formik.errors.password && (
                <PasswordChecklist
                  rules={[
                    'minLength',
                    'maxLength',
                    'specialChar',
                    'number',
                    'capital',
                    'lowercase',
                  ]}
                  messages={{
                    minLength: 'At least 8 characters.',
                    maxLength: 'At most 40 characters.',
                    lowercase: 'At least one lower case letter.',
                    capital: 'At least one upper case letter.',
                    number: 'At least one number.',
                    specialChar: 'At least one special character.',
                  }}
                  minLength={8}
                  maxLength={40}
                  value={password}
                  validTextColor="green"
                  invalidTextColor="#F64E60"
                  hideIcon
                />
              )}
            </div>
          </PasswordInput>
          {/* end: password */}

          {/* begin: confirm password */}
          <PasswordInput
            viewPassword={viewConfirmPassword}
            formik={formik}
            setViewPassword={setViewConfirmPassword}
            name="confirmPassword"
            placeholder="Confirm Password"
            passwordContainerSpacing={'mt-9 mb-7'}
          />
          {/* end: confirm password */}

          <ConfirmButton
            buttonText="Reset Password"
            loading={formik.isSubmitting}
            classes={'mt-5'}
            buttonWidth={'w-100'}
            buttonType={'submit'}
          />
        </form>
      </div>
    </>
  );
};

export default injectIntl(connect(null, auth.actions)(ResetPassword));
