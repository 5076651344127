import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { moneyFormat } from '../../../helpers/moneyFormat';
import { setUpRecurringPayments } from '../_redux/recurringPaymentAction';
import Alert from '../../Common/Alert';
import { DetailsItem } from '../../Common/DetailsItem';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { CommonSuccessModal } from '../../Common/CommonSuccessModal';
import ErrorModal from '../../Common/ErrorModal';
import { getYearsAndMonthsDisplay } from '../../../helpers/getYearsAndMonthsDisplay';
import Loader from '../../Common/Loader';
import * as recurringPaymentDataState from '../../RecurringPayments/_redux/recurringPaymentRedux';

const Confirmation = props => {
  const { handleStepChange, id: stepperId } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const recurringPaymentData = useSelector(state => state.recurringPayments);
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [loading, setLoading] = useState(false);
  const [openCongratulationsModal, setOpenCongratulationsModal] = useState(
    false,
  );

  useEffect(() => {
    handleStepChange({ next: stepperId });
  }, []);

  const mpesaSuccessModalContent = (
    <>
      <p>
        You have successfully submitted your recurring investment reminder. You
        will receive reminders on your account email.
      </p>
    </>
  );

  const fundDetails = [
    {
      label: 'Fund',
      value: recurringPaymentData?.planName
        ? `${recurringPaymentData?.planName}`
        : '---',
    },
    {
      label: 'Fund Currency',
      value: recurringPaymentData?.planCurrency
        ? `${recurringPaymentData?.planCurrency}`
        : '---',
    },
    {
      label: 'Amount',
      value: recurringPaymentData?.amount
        ? `${moneyFormat(recurringPaymentData?.amount)} ${
            recurringPaymentData?.currency
          }`
        : '---',
    },

    {
      label: 'Saving Period',
      value: getYearsAndMonthsDisplay(
        recurringPaymentData?.years,
        recurringPaymentData?.months,
      ),
    },
    {
      label: 'End date',
      value: recurringPaymentData?.endDate
        ? `${recurringPaymentData?.endDate}`
        : '---',
    },
    {
      label: 'Recurring Payment date',
      value: recurringPaymentData?.reminderDate
        ? `${recurringPaymentData?.reminderDate}`
        : '---',
    },
    {
      label: 'Frequency of payments',
      value: 'Monthly',
    },
  ];

  const mobileMoneyDetails = [
    {
      label: 'Phone number',
      value: recurringPaymentData?.phoneNumber
        ? `${recurringPaymentData?.phoneNumber}`
        : '---',
    },
  ];
  const handleSubmit = async () => {
    setLoading(true);
    const {
      years,
      planCurrency,
      planName,
      months,
      planSubscriptionId,
      planId,
      ...rest
    } = recurringPaymentData;
    try {
      await setUpRecurringPayments({
        ...rest,
        amount: parseFloat(recurringPaymentData.amount),
        planSubscriptionId,
      });
      setLoading(false);
      setOpenCongratulationsModal(true);
    } catch (error) {
      setLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage: error.response.data.message,
        alertMessageType: 'error',
      });
    }
  };

  return (
    <div className="mt-10 py-md-10 py-5 px-5">
      <div className="big-container mx-auto border-light-blue-545 border-radius-8">
        {alertOn && (
          <div className="mb-5">
            <Alert alert={alert} />
          </div>
        )}
        {loading ? (
          <Loader />
        ) : (
          <div className="px-md-5 px-3 py-5">
            <div className="account-container bg-white border-radius-9">
              <div className="text-center mb-1">
                <h1 className="mb-1 text-left text-20 font-weight-600 summary-heading">
                  Recurring Investment details
                </h1>
                <p className="text-left">
                  Review the information you provided below
                </p>
              </div>
              <h1 className="summary-subtitle">Standing Order Details</h1>
              {fundDetails.map(detail => (
                <DetailsItem
                  key={detail.label}
                  label={detail.label}
                  value={detail.value}
                  valueAdditionalClasses={'confirmation-details-item-text'}
                  changeValueString={false}
                />
              ))}
              <>
                <h1 className="summary-subtitle">Mpesa details</h1>

                {mobileMoneyDetails.map(detail => (
                  <DetailsItem
                    key={detail.label}
                    label={detail.label}
                    value={detail.value}
                    valueAdditionalClasses={'confirmation-details-item-text'}
                    changeValueString={false}
                  />
                ))}
              </>

              <ButtonsContainer justifyContent="justify-content-between">
                <CancelButton
                  buttonText="Previous"
                  handleOnClick={() =>
                    history.push('/recurring-payments/bank-details')
                  }
                  twinButton={true}
                />

                <ConfirmButton
                  buttonText="Complete"
                  loading={loading}
                  handleOnClick={handleSubmit}
                  twinButton={true}
                />
              </ButtonsContainer>

              {recurringPaymentData?.planCurrency !==
                recurringPaymentData?.currency && (
                <div className="text-left mt-5">
                  <strong>Note: </strong>
                  Please note that as your mobile money currency does not match
                  the fund currency, Ndovu will use up-to-date currency
                  conversion rates for your transactions.
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <CommonSuccessModal
        open={openCongratulationsModal}
        setOpen={() => setOpenCongratulationsModal(false)}
        content={mpesaSuccessModalContent}
        moreClasses={'bg-white'}
        handleButtonClick={() => {
          history.push(
            `/portfolio/viewInvestment/${recurringPaymentData?.planSubscriptionId}`,
          );
          dispatch(
            recurringPaymentDataState?.actions.resetRecurringPaymentData(),
          );
        }}
      />
      <ErrorModal
        backgroundColor="bg-orange-100"
        open={alertOn}
        content={<>{alert?.alertMessage}</>}
        setOpen={setAlertOn}
      />
    </div>
  );
};

export default Confirmation;
