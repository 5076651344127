import jwtDecode from 'jwt-decode';

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const authToken = localStorage.getItem('ndovuToken');
      const idToken = localStorage.getItem('idToken');

      if (idToken) {
        config.headers['id-token'] = idToken;
      }

      if (authToken && authToken !== null) {
        const decodedToken = jwtDecode(authToken);
        if (decodedToken.exp * 1000 < Date.now()) {
          localStorage.removeItem('ndovuToken');
          window.location.replace('/auth/login');
          return;
        }
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err),
  );

  axios.interceptors.response.use(
    response => response,
    error => {
      // Automatically logout out the user if unauthorized
      if (error.response?.status === 401) {
        localStorage.clear();
        window.location.replace('/auth/login');
      }

      return Promise.reject(
        error.code === 'ECONNABORTED' || error.message.includes('timeout')
          ? {
              // Construct response similar to error message from axios
              response: {
                data: {
                  message:
                    'Request timeout before it completes. Try again later',
                },
              },
            }
          : error,
      );
    },
  );
}
