import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Currency,
  numbersRegex,
  paymentOptions,
} from '../../../helpers/constants';
import convertAmountToNumber from '../../../helpers/convertAmountToNumber';
import { findCurrency } from '../../../helpers/findCurreny';
import { addCommas } from '../../../helpers/handleInput';
import { getFullTargetDate } from '../../../helpers/investmentHelpers';
import { moneyFormat } from '../../../helpers/moneyFormat';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { validatePlanInvestment } from '../../Pocket/helpers/minimumAndMaximumValidation';
import * as recurringPaymentDataState from '../_redux/recurringPaymentRedux';
import RecurringPaymentCalculator from '../components/RecurringPaymentCalculator';

function RecurringPayment(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleStepChange, id: stepperId } = props;
  const planDetails = useSelector(state => state.plan.viewedPlan);
  const {
    currencies: { currencies: allCurrencies },
  } = useSelector(state => state);

  const recurringPaymentData = useSelector(state => state.recurringPayments);
  const maxYears = 12;
  const [months, setMonths] = useState(
    recurringPaymentData?.months ? recurringPaymentData?.months : 0,
  );
  const [years, setYears] = useState(
    recurringPaymentData?.years ? recurringPaymentData?.years : 3,
  );

  const [validationError, setValidationError] = useState();
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    handleStepChange({ next: stepperId });
  }, []);

  const [recurringPaymentPayload, setRecurringPaymentPayload] = useState({
    years: 0,
    amount: recurringPaymentData?.amount ? recurringPaymentData?.amount : 0,
    currency: recurringPaymentData?.currency
      ? recurringPaymentData?.currency
      : Currency.KES,
  });
  const handleYearChange = event => {
    const { value, id } = event.target;
    if (!numbersRegex.test(value)) {
      return false;
    }
    if (id === 'years') {
      if (value >= maxYears) {
        setYears(maxYears);
        setMonths(0);
      } else {
        setYears(Number(value));
      }
    }

    if (id === 'months') {
      if (years >= maxYears) {
        setMonths(0);
      } else if (value >= 12) {
        const numberOfYears = Number((value / 12).toString().split('.')[0]);
        const totalYears = Number(years) + numberOfYears;
        setYears(totalYears >= maxYears ? maxYears : totalYears);
        setMonths(Number(value % 12));
      } else {
        setMonths(Number(value));
      }
    }
  };
  // update the target date based on the new values entered by the user.
  useEffect(() => {
    setEndDate(getFullTargetDate(years, months));
  }, [years, months]);

  const handleValidation = (
    validationFunctionArguments,
    keepRunningTheCode,
  ) => {
    const returnedErrorMessage = validatePlanInvestment(
      validationFunctionArguments,
      planDetails,
    );

    if (returnedErrorMessage) {
      setValidationError({
        ...validationError,
        amount: returnedErrorMessage,
      });
    } else {
      setValidationError({
        ...validationError,
        amount: null,
      });
    }

    // we should not stop running the code inside the function which handle onChange on input
    return returnedErrorMessage && !keepRunningTheCode ? true : false;
  };
  const handleChange = event => {
    const { name, value } = event.target;
    setValidationError({ ...validationError, [name]: null });
    const convertTonumber = convertAmountToNumber(value);

    if (!numbersRegex.test(value)) {
      return false;
    }

    if (name === 'targetDate') {
      setYears(
        Number(
          Number(convertTonumber / 12)
            .toString()
            .split('.')[0],
        ),
      );
      setMonths(Number(convertTonumber % 12));
    } else if (
      name === 'initialAmount' ||
      name === 'goalAmount' ||
      name === 'amount'
    ) {
      setRecurringPaymentPayload({
        ...recurringPaymentPayload,
        [name]: moneyFormat(convertAmountToNumber(value)),
      });
      const currencyDetails = findCurrency(
        allCurrencies,
        recurringPaymentPayload?.currency,
      );

      // check if amount is greater than the maximum for mpesa for recurring payments whose payment channel is m-pesa

      if (recurringPaymentData.paymentChannel === paymentOptions.MPESA) {
        if (convertAmountToNumber(value) > convertAmountToNumber(250000)) {
          setValidationError({
            ...validationError,
            amount: 'The maximum amount payable via Mpesa is 250,000',
          });
          return;
        }
      }

      const validationFunctionArguments = {
        amount: moneyFormat(convertAmountToNumber(value)),
        currency: currencyDetails,
        operation: 'investment',
        pocketBalance: 0,
      };

      handleValidation(validationFunctionArguments, true);
    }
  };
  const setCurrency = currency => {
    setRecurringPaymentPayload({
      ...recurringPaymentPayload,
      currency: currency,
    });
    const currencyDetails = findCurrency(allCurrencies, currency);
    // also call the validation since the currency has changed
    handleValidation(
      {
        amount: recurringPaymentPayload.amount,
        currency: currencyDetails,
        operation: 'investment',
        pocketBalance: 0,
      },
      true,
    );
  };

  const handleNext = async () => {
    let errors = {};

    if (!recurringPaymentPayload.years || !months) {
      errors.years = 'Years is required';
    }
    if (!recurringPaymentPayload.amount) {
      setValidationError({
        ...validationError,
        amount: `The amount is required`,
      });
    }

    dispatch(
      recurringPaymentDataState?.actions.updateRecurringPaymentData({
        years: years,
        months,
        amount: convertAmountToNumber(recurringPaymentPayload.amount),
        endDate: getFullTargetDate(years, months),
        currency: recurringPaymentPayload.currency,
      }),
    );

    history.push(
      recurringPaymentData?.paymentChannel === paymentOptions.BANK_TRANSFER
        ? '/recurring-payments/bank-confirmation'
        : '/recurring-payments/payments',
      { previousStep: 1 },
    );
  };

  const handlePrevious = () => {
    //  redirect user and also clean  recurring payment data
    history.push(
      `/portfolio/viewInvestment/${recurringPaymentData?.planSubscriptionId}`,
    );
    dispatch(recurringPaymentDataState?.actions.resetRecurringPaymentData());
  };

  return (
    <>
      <section className="mt-10">
        <RecurringPaymentCalculator
          handleChange={handleChange}
          handleYearChange={handleYearChange}
          years={years}
          months={months}
          amount={addCommas(recurringPaymentPayload?.amount)}
          validationError={validationError}
          endDate={endDate}
          setCurrency={setCurrency}
          recurringPaymentData={recurringPaymentData}
        />

        <div
          className="w-100"
          style={{
            margin: 'auto',
          }}
        >
          <ButtonsContainer justifyContent="justify-content-between">
            <CancelButton
              buttonText="Previous"
              handleOnClick={() => handlePrevious()}
              twinButton
            />
            <ConfirmButton
              buttonText={'Next'}
              handleOnClick={handleNext}
              twinButton
              disable={
                (!years && !months) ||
                !recurringPaymentPayload?.amount ||
                validationError?.amount
              }
            />
          </ButtonsContainer>
        </div>
      </section>
    </>
  );
}

export default RecurringPayment;
