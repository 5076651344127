export const clearLocalStorageOnLogout = () => {
  const zohoCookieKey = 'zoho_cookie';

  const zohoCookieValue = JSON.parse(localStorage.getItem(zohoCookieKey));

  localStorage.clear();

  if (zohoCookieValue !== null) {
    localStorage.setItem(zohoCookieKey, JSON.stringify(zohoCookieValue));
  }
};

const logout = () => {
  clearLocalStorageOnLogout();
  window.location.replace('/auth/login');
};

export default logout;
