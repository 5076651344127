import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../_core/MetronicLayout';

import ndovuLogo from '../../../../app/icons/ndovuLogo.svg';

export function Brand() {
  const uiService = useHtmlClassService();
  const profile = useSelector(state => state.profile);

  const { investor } = profile;

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses('brand', true),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        'aside.self.minimize.toggle',
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo(),
    };
  }, [uiService]);

  const checkCorporateCode =
    investor?.voucherCorporate && investor.voucherCorporate.logoLink !== null;

  const brandContainerClasses = 'flex-column-auto brand-container';

  const displayLogo = () => {
    if (investor?.originPartner?.logoUrl) {
      return (
        <img
          alt="logo"
          src={investor?.originPartner?.logoUrl}
          className="ndovu-logo"
        />
      );
    } else if (checkCorporateCode) {
      return (
        <img
          alt="logo"
          src={investor.voucherCorporate.logoLink}
          className="ndovu-logo"
        />
      );
    } else {
      return <img alt="logo" src={ndovuLogo} className="ndovu-logo" />;
    }
  };

  return (
    <>
      {/* begin::Brand */}
      {!profile?.loading ? (
        <div
          className={
            checkCorporateCode || investor?.originPartner?.logoUrl
              ? `partner-brand ml-5 ${brandContainerClasses} ${layoutProps.brandClasses}`
              : `ml-5 brand ${brandContainerClasses} ${layoutProps.brandClasses}`
          }
          id="kt_brand"
        >
          {/* begin::Logo */}
          <Link
            to=""
            className={`${
              checkCorporateCode ? 'partner-brand-logo' : 'brand-logo'
            }`}
          >
            {displayLogo()}
          </Link>
          {/* end::Logo */}
        </div>
      ) : (
        <div className="loading-logo"></div>
      )}
      {/* end::Brand */}
    </>
  );
}
