import React from 'react';
import { Route, Switch } from 'react-router-dom';
import WithdrawalBankDetails from './pages/WithdrawalBankDetails';
import WithdrawalDetailsMobileMoney from './pages/WithdrawalDetailsMobileMoney';
import WithdrawalDetailsPage from './pages/WithdrawalDetailsPage';

const WithdrawalDetails = ({ match }) => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}/`}
        render={props => <WithdrawalDetailsPage {...props} />}
      />

      <Route
        exact
        path={`${match.path}/bank-details`}
        component={WithdrawalBankDetails}
      />
      <Route
        exact
        path={`${match.path}/bank-details/edit/:id`}
        component={WithdrawalBankDetails}
      />

      <Route
        exact
        path={`${match.path}/mobile-money`}
        component={WithdrawalDetailsMobileMoney}
      />
      <Route
        exact
        path={`${match.path}/mobile-money/edit/:id`}
        component={WithdrawalDetailsMobileMoney}
      />
    </Switch>
  );
};

export default WithdrawalDetails;
