import { accountTypes, kycStatuses } from '../../../helpers/constants';

const commonLinks = {
  withdrawalDetails: {
    link: '/withdrawal-details',
    text: 'Withdrawal Details',
    id: 2,
    eventName: 'Withdraw_Details',
  },

  teamMembers: {
    link: '/manage-account/team-members',
    text: 'Team Members',
    id: 3,
  },

  security: {
    link: '/manage-account/security',
    text: 'Security',
    id: 4,
    eventName: 'Security_Click',
  },

  accountPlans: { link: '/subscription', text: 'Account Plans', id: 5 },

  preferences: {
    link: '/manage-account/preferences',
    text: 'Preferences',
    id: 6,
  },
};

const kycLinks = ({ linkText, accountType, eventName, basePath }) => ({
  link:
    accountType === accountTypes.INDIVIDUAL
      ? {
          [kycStatuses.PENDING]: '/kyc-information',
          [kycStatuses.SUBMITTED]: '/manage-account/information',
          [kycStatuses.COMPLETED]: '/manage-account/information',
          [kycStatuses.REJECTED]: '/manage-account/information',
        }
      : {
          [kycStatuses.PENDING]: `${basePath}`,
          [kycStatuses.SUBMITTED]: `${basePath}/summary`,
          [kycStatuses.COMPLETED]: `${basePath}/summary`,
          [kycStatuses.REJECTED]: `${basePath}/summary`,
        },
  text: linkText,
  kyc: true,
  id: 1,
  eventName,
});

export const PersonalManagement = [
  kycLinks({
    linkText: 'Personal Information',
    accountType: accountTypes.INDIVIDUAL,
    eventName: 'Personal_Information',
  }),

  commonLinks.withdrawalDetails,

  { link: '/manage-account/team-invitations', text: 'Team Invitations', id: 3 },
  commonLinks.security,
  commonLinks.accountPlans,
  commonLinks.preferences,
];

export const BusinessManagement = [
  kycLinks({
    linkText: 'Business Information',
    accountType: accountTypes.BUSINESS,
    basePath: '/investor-information',
  }),
  commonLinks.withdrawalDetails,
  commonLinks.teamMembers,
  commonLinks.security,
  commonLinks.accountPlans,
  commonLinks.preferences,
];

export const ChamaManagement = [
  kycLinks({
    linkText: 'Chama Information',
    accountType: accountTypes.CHAMA,
    basePath: '/investor-information',
  }),
  commonLinks.withdrawalDetails,
  commonLinks.teamMembers,
  commonLinks.security,
  commonLinks.accountPlans,
  commonLinks.preferences,
];
