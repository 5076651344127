import React from 'react';

export const KycCompletedModalContent = ({ userIsUpdating, investor }) => {
  return (
    <>
      {userIsUpdating ? (
        <>
          You have successfully updated your account information, please note
          that verification may take up to{' '}
          <span className="font-weight-600">48 hours</span>
        </>
      ) : investor?.kycExtractionMatch && investor?.kycLevel?.level === 1 ? (
        <div>You have successfully submitted your account information.</div>
      ) : (
        <>
          You have successfully submitted your account information, please note
          that verification may take up to{' '}
          <span className="font-weight-600">48 hours</span>
        </>
      )}
    </>
  );
};
