import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion/dist/framer-motion';

export const AnimatedPageContainer = React.memo(({ children }) => {
  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      y: 0,
      transition: { type: 'tween', duration: 1, ease: 'easeOut' },
    });
  }, [controls]);

  return (
    <motion.div initial={{ y: '-100vw' }} animate={controls}>
      {children}
    </motion.div>
  );
});
