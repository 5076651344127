import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import * as auth from '../_redux/authRedux';
import { requestPassword } from '../_redux/authCrud';

import ExternalPageTitle from '../../Common/ExternalPageTitle/ExternalPageTitle';
import CustomInput from '../../Common/CustomInput';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import { DisplayErrorOrSuccessMessage } from '../Components/ErrorAndSuccessMessage';

const ForgotPassword = props => {
  const { intl } = props;
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [disableButton, setDisableButton] = useState(false);

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 Characters')
      .max(50, 'Maximum 50 Characters')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
  });

  const initialValues = {
    email: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const investorEmail = values.email.toLowerCase();

      try {
        setDisableButton(true);
        const response = await requestPassword(investorEmail);
        setSubmitting(false);

        if (response.status === 200) {
          setErrorMessage(null);
          setSuccessMessage(
            <span>
              We have sent a password reset link to your email address:{' '}
              <strong>{investorEmail}</strong>. Please check your inbox and
              reset your password.{' '}
              <strong>The link will expire in an hour</strong>
            </span>,
          );
          setSuccess(true);
        }
      } catch (error) {
        setDisableButton(false);
        setSubmitting(false);
        setSuccess(false);
        setSuccessMessage(null);
        setErrorMessage(error.response.data.message);
      }
    },
  });

  return (
    <>
      <div className="login-form login-forgot" style={{ display: 'block' }}>
        <ExternalPageTitle
          title="Forgotten Password?"
          subTitle="Enter the email address associated with your ndovu account to have a password reset request emailed to you"
        />
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework mt-10"
        >
          <DisplayErrorOrSuccessMessage
            success={success}
            successMessage={successMessage}
            errorMessage={errorMessage}
          />

          <CustomInput
            type="email"
            placeholder="Email"
            name="email"
            formik={formik}
            inputWidth={'auth-page-input-container'}
          />

          <ButtonsContainer justifyContent="justify-content-between">
            <div className="auth-buttons-size">
              <Link to="/auth/login" className="w-100">
                <CancelButton buttonText="Cancel" buttonWidth={'w-100'} />
              </Link>
            </div>

            <ConfirmButton
              buttonType={'submit'}
              buttonText="Submit"
              loading={formik?.isSubmitting}
              disable={
                !formik?.values?.email || formik?.errors?.email || disableButton
              }
              buttonWidth={'auth-buttons-size'}
            />
          </ButtonsContainer>
        </form>
      </div>
    </>
  );
};

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
