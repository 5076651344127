import React from 'react';
import SVG from 'react-inlinesvg';

import HorizontalLine from '../../../../helpers/horizontalLine';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import { trackEventMixpanel } from '../../../../eventTracking/mixpanel';

const Document = ({ factSheet, prospectus, iconA, iconB, name }) => {
  return (
    <div
      className="cursor-pointer"
      onClick={() => {
        factSheet && trackEventMixpanel('Factsheet_PDF_Open');
        window.open(factSheet ? factSheet : prospectus ? prospectus : '#');
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <img src={iconA} alt="PDFIcon" />
        <p className="text-13">{name}</p>
        <div>
          <SVG src={toAbsoluteUrl('/media/svg/ndovu/arrow.svg')}></SVG>
        </div>
      </div>
      <HorizontalLine color="#DFE2E6" width={'100%'} height={0.1} />
    </div>
  );
};

export default Document;
