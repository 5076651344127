/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss';
import '../styles/registration.scss';

import { Redirect, Switch } from 'react-router-dom';
import AfterRegMessage from './AfterRegMessage';
import AppFooter from '../../../utils/appFooter';
import { ContentRoute } from '../../../../_metronic/layout';
import DisplayAppropriateContent from '../../Common/DisplayAppropriateContent';
import ExternaLeft from '../../Common/ExternalLeft';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import Registration from './Registration';
import ResendEmailVerification from './ResendEmailVerification';
import ResetPassword from './ResetPassword';
import SignupEmailVerification from './SignupEmailVerification';
import { useHistory } from 'react-router-dom';
import { fetchOriginPartner } from '../_redux/authCrud';
import { actions as authActions } from '../../Auth/_redux/authRedux';
import { useDispatch, useSelector } from 'react-redux';
import AccountType from './AccountType';
import AccountDetails from './AccountDetails';
import { SelectSignupOption } from './SelectSignupOption';
import PhoneNumberVerification from './PhoneNumberVerification';
import GoogleCallback from './GoogleCallBack';

export const AuthPage = () => {
  const history = useHistory();
  history.listen((location, action) => {});

  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const originPartnerLogo = useSelector(state => state.auth?.authPageLogo);

  // origin-partner querry attributes
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const boolOriginPartnerId = urlParams.has('OriginPartnerId');
  const originPartnerId = urlParams.get('OriginPartnerId');
  const firstName = urlParams.get('firstName');
  const lastName = urlParams.get('lastName');
  const email = urlParams.get('email');
  const phoneNumber = urlParams.get('phoneNumber');

  // utm querry attributes
  const tracking_id = urlParams.get('utm_id');
  const source = urlParams.get('utm_source');
  const medium = urlParams.get('utm_medium');
  const campaign = urlParams.get('utm_campaign');
  const term = urlParams.get('utm_term');
  const content = urlParams.get('utm_content');

  const campaignTrackingData = {
    tracking_id,
    source,
    medium,
    campaign,
    term,
    content,
  };

  const fetchOriginatingPartner = async () => {
    try {
      const res = await fetchOriginPartner(originPartnerId);
      if (res.status === 200) {
        const partner = res.data.data;
        const { logoUrl } = partner;
        dispatch(authActions.updateAuthPageLogo(logoUrl));
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  useEffect(() => {
    if (originPartnerId) {
      fetchOriginatingPartner();
    }
  }, []);

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid"
          id="kt_login"
          style={{ backgroundColor: '#fff' }}
        >
          {/*begin::Aside*/}
          <ExternaLeft
            originPartnerLogo={originPartnerLogo}
            boolOriginPartnerId={boolOriginPartnerId}
          >
            <DisplayAppropriateContent />
          </ExternaLeft>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            {/* begin::Content body */}

            {errorMessage ? (
              <div className="mb-10 ">
                <div
                  className="text-center font-weight-bold"
                  style={{ color: '#F05B7F' }}
                >
                  {errorMessage}
                </div>
              </div>
            ) : null}

            <div className="d-flex flex-column-fluid flex-center mt-5 mt-lg-15">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/google-callback"
                  component={GoogleCallback}
                />
                <ContentRoute
                  path="/auth/select-sign-up-option"
                  component={SelectSignupOption}
                ></ContentRoute>
                <ContentRoute path="/auth/registration">
                  <Registration
                    originPartnerId={originPartnerId}
                    campaignTrackingData={campaignTrackingData}
                    userDetails={{ firstName, lastName, email, phoneNumber }}
                  />
                </ContentRoute>

                <ContentRoute
                  path="/auth/enter-phone-number"
                  component={PhoneNumberVerification}
                />

                <ContentRoute
                  path="/auth/selectAccountType"
                  component={AccountType}
                />

                <ContentRoute
                  path="/auth/accountDetails"
                  component={AccountDetails}
                />

                <ContentRoute
                  path="/auth/success"
                  component={AfterRegMessage}
                />

                <ContentRoute
                  path="/auth/resendVerification"
                  component={ResendEmailVerification}
                />

                <ContentRoute
                  path="/auth/resetpassword"
                  component={ForgotPassword}
                />

                <ContentRoute
                  path="/auth/resetPasswordConfirmation"
                  component={ResetPassword}
                />

                <ContentRoute
                  path="/auth/verify"
                  component={SignupEmailVerification}
                />

                <Redirect from="/auth" exact={true} to="/auth/login" />

                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none justify-content-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                <div className="font-weight-bold text-center">
                  <AppFooter />
                </div>
              </div>
              {/* remove div for links */}
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
};
