import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ManageAccount from './ManageAccount';
import AccountSetting from './AccountSetting';
import Subscription from './Subscription';
import PersonalDetails from '../components/PersonalDetails';
import Documents from '../components/Documents';
import UploadDocuments from './UploadDocuments';
import KycProgressBar from '../../KYC/helpers/KycProgressBar';
import InstructionStageTwo from './Instructions';
import TeamMembers from './TeamMembers';
import AddTeamMember from './AddTeamMember';
import AssignRole from './AssignRole';
import Preferences from './Preferences';
import Invites from './Invites';
import NoAccess from '../../Business/pages/NoAccess';
import Summary from '../../Individual/pages/Summary';
import { AnimatedPageContainer } from '../../Common/AnimatedPageContainer';

const ManageUserAccount = ({ match }) => {
  const { investor } = useSelector(state => state.profile);
  const [stepperData, setStepperData] = useState([
    { id: 1, text: 'Investor Info', done: false },
    { id: 2, text: 'Documents', done: false },
  ]);

  const [
    stageTwoAndThreeStepperData,
    setStageTwoAndThreeStepperData,
  ] = useState([
    { id: 1, text: 'Residential Address', done: false },
    { id: 2, text: 'Documents', done: false },
  ]);

  const [currentTeamMemberId, setCurrentTeamMemberId] = useState();

  const stageTwoAndThreeUrls = [
    '/manage-account/investor-info/personal-address',
    '/manage-account/investor-info/upload-documents',
  ];

  const handleStepChange = id => {
    const updateStep = data => {
      return data.map(step => {
        let doneValue;
        if (id?.next) {
          doneValue = true;
        }

        return step.id <= id?.next || step.id <= id?.previous
          ? { ...step, done: doneValue }
          : step;
      });
    };
    stageTwoAndThreeUrls.includes(window.location.pathname)
      ? setStageTwoAndThreeStepperData(updateStep(stageTwoAndThreeStepperData))
      : setStepperData(updateStep(stepperData));
  };

  const pagesWithNoBreadAcrumb = [
    '/manage-account/investor-info/instructions',
    '/manage-account/information',
    '/manage-account/preferences',
    '/manage-account',
    '/manage-account/security',
    '/manage-account/preferences',
    '/manage-account/team-members',
    '/manage-account/invite-member',
    '/manage-account/team-invitations',
    `/manage-account/member-details/${currentTeamMemberId}`,
  ];

  const getTeamMemberId = teamMemberId => {
    setCurrentTeamMemberId(teamMemberId);
  };

  return (
    <AnimatedPageContainer>
      {pagesWithNoBreadAcrumb?.includes(window.location.pathname) ||
      (investor?.kycLevel?.level === 3 &&
        investor?.pendingKyc?.length > 0) ? null : (
        <KycProgressBar
          stepperData={
            stageTwoAndThreeUrls.includes(window.location.pathname)
              ? stageTwoAndThreeStepperData
              : stepperData
          }
        />
      )}

      {/* TODO: ADD PERMISSIONS CHECK ON ROUTES */}
      <Switch>
        <Route exact path={`${match.path}`} component={ManageAccount} />

        <Route
          exact
          path={`${match.path}/information`}
          render={props => <Summary {...props} updating={true} />}
        />

        <Route
          exact
          path={`${match.path}/security`}
          render={props => <AccountSetting {...props} />}
        />
        <Route
          exact
          path={`${match.path}/subscription`}
          component={Subscription}
        />

        {/* Preferences */}
        <Route
          exact
          path={`${match.path}/preferences`}
          render={props => <Preferences {...props} />}
        />

        {/* Personal KYC New routes */}
        <Route
          exact
          path={`${match.path}/investor-information/details`}
          render={props => (
            <PersonalDetails
              handleStepChange={handleStepChange}
              id={1}
              hideButtons={true}
              {...props}
            />
          )}
        />

        <Route
          exact
          path={`${match.path}/investor-information/documentation`}
          render={props => (
            <Documents
              hideButtons={true}
              {...props}
              handleStepChange={handleStepChange}
              id={2}
            />
          )}
        />

        <Route
          exact
          path={`${match.path}/investor-info/upload-documents`}
          render={props => (
            <UploadDocuments
              handleStepChange={handleStepChange}
              id={2}
              {...props}
            />
          )}
        />

        {/* Preferences */}
        <Route
          exact
          path={`${match.path}/preferences`}
          render={props => <Preferences {...props} />}
        />

        <Route
          exact
          path={`${match.path}/investor-info/instructions`}
          render={props => <InstructionStageTwo {...props} />}
        />

        {/* Business and Chama routes */}
        <Route
          exact
          path={`${match.path}/team-members`}
          render={props => <TeamMembers {...props} />}
        />

        {/* Team Roles */}
        <Route
          exact
          path={`${match.path}/invite-member`}
          render={props => <AddTeamMember {...props} />}
        />
        <Route
          exact
          path={`${match.path}/team-invitations`}
          render={props => <Invites {...props} />}
        />
        <Route
          exact
          path={`${match.path}/member-details/:id`}
          render={props => (
            <AssignRole {...props} getTeamMemberId={getTeamMemberId} />
          )}
        />

        {/* permissions */}
        <Route
          exact
          path={`${match.path}/no-access`}
          render={props => <NoAccess {...props} />}
        />
      </Switch>
    </AnimatedPageContainer>
  );
};

export default ManageUserAccount;
