import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import CustomInput from '../../Common/CustomInput';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import Back from '../../Common/BackArrow';
import ReusableDropdown from '../../Common/ReusableDropdown';
import {
  Currency,
  settlementDetailsStatuses,
  otherBank,
  specialCharError,
  letterNumberRegex,
} from '../../../helpers/constants';
import OptionsModal from '../../Common/OptionsModal';
import { FieldErrorMessage } from '../../Individual/components/FieldErrorMessage';
import { checkForErrors } from '../../../helpers/checkForErrors';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../helper';
import { KycPageContentWrapper } from '../../Common/KycPageContentWrapper/KycPageContentWrapper';
import {
  createSettlementDetails,
  fetchSingleDetail,
  updateSettlementDetails,
  fetchBankList,
} from '../_redux/withdrawalCrud';
import { CommonSuccessModal } from '../../Common/CommonSuccessModal';
import ErrorModal from '../../Common/ErrorModal/index';
import BankModal from '../../Common/BankModal';
import Loader from '../../Common/Loader';
import { DropdownContainer } from '../../Common/ReusableDropdown/DropDownContainer';
import { DropdownTitle } from '../../Common/Dropdown/DropdownTitle';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import { AnimatedPageContainer } from '../../Common/AnimatedPageContainer';

const WithdrawalBankDetails = () => {
  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const [show, setShow] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openBankNames, setOpenBankNames] = useState(false);
  const [errorExist, setErrorExist] = useState('');
  const [bankData, setBankData] = useState([]);
  const [bankNameErrorMessage, setBankNameErrorMessage] = useState();
  const [updating, setUpdating] = useState(false);

  const [selectedOption, setSelectedOption] = useState({
    currency: { label: 'KES - Kenyan Shilling', value: Currency.KES },
    bankName: { name: '', bankId: '' },
  });

  const {
    currencies: { currencies },
  } = useSelector(state => state);

  const closeDropdown = () => {
    setOpenDropdown(false);
  };

  const closeBankNames = () => {
    setOpenBankNames(false);
  };
  const handleBankNamesDropdown = () => {
    setOpenBankNames(!openBankNames);
  };

  const handleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleSelectOption = (item, name) => {
    setSelectedOption({ ...selectedOption, [name]: item });
    closeDropdown();
    closeBankNames();
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const initialValues = {
    bankName: detailsData?.bankName ? detailsData.bankName : '',
    accountName: detailsData?.accountName ? detailsData.accountName : '',
    accountNumber: detailsData?.accountNumber ? detailsData.accountNumber : '',
    swiftCode: detailsData?.swiftCode ? detailsData.swiftCode : '',
    bankBranch: detailsData?.bankBranch ? detailsData.bankBranch : '',
  };

  const ValidationSchema = Yup.object().shape({
    accountName: Yup.string()
      .min(2, 'Minimum 2 Characters')
      .max(50, 'Maximum 50 Characters')
      .matches(letterNumberRegex, specialCharError)
      .required('Account Name is Required'),

    accountNumber: Yup.string()
      .min(5, 'Minimum 5 Characters')
      .max(50, 'Maximum 50 Characters')
      .matches(letterNumberRegex, specialCharError)
      .required('Account Number is Required'),

    swiftCode: Yup.string()
      .min(2, 'Minimum 2 Characters')
      .max(50, 'Maximum 50 Characters')
      .matches(letterNumberRegex, specialCharError)
      .required('SWift code is Required'),

    bankBranch: Yup.string()
      .min(2, 'Minimum 2 Characters')
      .max(50, 'Maximum 50 Characters')
      .matches(letterNumberRegex, specialCharError)
      .required('Branch name is Required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: ValidationSchema,
  });

  const customKesBankNameIsAllowed =
    selectedOption?.bankName.name === 'Others' &&
    selectedOption.currency.value === Currency.KES;

  const getBankNameFromInput =
    customKesBankNameIsAllowed ||
    selectedOption.currency.value !== Currency.KES;

  const additionalRequiredValues = getBankNameFromInput
    ? {
        currency: selectedOption?.currency?.value,
      }
    : {
        currency: selectedOption?.currency?.value,
        bankName: selectedOption?.bankName?.name,
      };

  const handleSubmit = async () => {
    setUpdating(false);
    if (getBankNameFromInput && !formik?.values?.bankName) {
      setBankNameErrorMessage('Bank Name is required');
      return;
    }

    const noErrors = checkForErrors(
      {
        ...formik?.values,
        ...additionalRequiredValues,
      },
      getBankNameFromInput ? ['bankName'] : [],
    );

    if (!noErrors) {
      setErrorExist(true);
      return;
    }

    if (Object.keys(formik.errors).length > 0) {
      return;
    }

    const {
      bankName,
      accountName,
      accountNumber,
      swiftCode,
      bankBranch,
    } = formik.values;

    const { currency, bankName: kenyaBankName } = selectedOption;

    try {
      setLoading(true);

      const res = await createSettlementDetails({
        bankName: getBankNameFromInput ? bankName : kenyaBankName.name,
        bankBranch,
        accountName,
        accountNumber,
        swiftCode,
        currency: currency?.value,
        type: 'BANK',
        serviceProvider: 'BANK',
      });

      if (res && res.status === 201) {
        setLoading(false);
        setShow(true);
      }
    } catch (error) {
      setLoading(false);
      setShow(false);
      setOpenError(true);
      setErrorText(error.response.data.message || error.message);
    }
  };

  useEffect(() => {
    setBankNameErrorMessage(null);
  }, [formik.values?.bankName]);

  const updateDetail = async e => {
    e.preventDefault();
    setUpdating(true);

    if (getBankNameFromInput && !formik?.values?.bankName) {
      setBankNameErrorMessage('Bank Name is required');
      return;
    }

    const noErrors = checkForErrors(
      {
        ...formik?.values,
        ...additionalRequiredValues,
      },
      getBankNameFromInput ? ['bankName'] : [],
    );

    if (!noErrors) {
      setErrorExist(true);
      return;
    }

    if (Object.keys(formik.errors).length > 0) {
      return;
    }

    const {
      bankName,
      accountName,
      accountNumber,
      swiftCode,
      bankBranch,
    } = formik.values;

    const { currency, bankName: kenyaBankName } = selectedOption;

    if (detailsData?.approvalStatus !== settlementDetailsStatuses.SUBMITTED) {
      setOpenError(true);
      setErrorText(
        `Settlement details with status of Submitted can only be updated`,
      );
      return;
    }

    try {
      setLoading(true);
      const res = await updateSettlementDetails(
        {
          bankName: getBankNameFromInput ? bankName : kenyaBankName.name,
          bankBranch,
          accountName,
          accountNumber,
          swiftCode,
          currency: currency?.value,
        },
        id,
      );
      if (res && res.status === 200) {
        setLoading(false);
        setShow(true);
      }
    } catch (error) {
      setLoading(false);
      setShow(false);
      setOpenError(true);
      setErrorText(error.response.data.message || error.message);
    }
  };

  const fetchDetail = async () => {
    try {
      setStatus(true);
      const res = await fetchSingleDetail(id);
      if (res?.status === 200) {
        setDetailsData(res?.data);
        setSelectedOption({
          currency: {
            label: res?.data.currency.name,
            value: res?.data.currency.currency,
          },
          bankName: { name: res?.data?.bankName, bankId: '' },
        });
        setStatus(false);
      }
    } catch (error) {}
  };

  const fetchBanksList = async () => {
    try {
      setStatus(true);
      const res = await fetchBankList();
      if (res.status === 200) {
        setBankData(res?.data?.data);
      }
      setStatus(false);
    } catch (error) {}
  };

  const content = (
    <p>
      {updating
        ? `Your bank details have been updated successfully.`
        : `Your bank details have been submitted successfully.`}
    </p>
  );

  useEffect(() => {
    id && fetchDetail();
  }, [id]);

  useEffect(() => {
    fetchBanksList();
  }, []);

  if (status) {
    return <Loader />;
  }

  return (
    <AnimatedPageContainer>
      <div className="mt-5">
        <Back title="Bank Details" />
        <>
          <form
            className="px-md-20 px-3"
            onSubmit={id ? formik.updateDetail : formik.handleSubmit}
          >
            <KycPageContentWrapper>
              <div className="d-flex justify-content-between flex-wrap">
                <DropdownContainer>
                  <DropdownTitle title={'Currency'} />

                  <ReusableDropdown
                    handleOpenSelector={handleDropdown}
                    dropdownPlaceholder={''}
                    selectedOption={selectedOption?.currency?.label}
                    classes={'pr-5 pl-2'}
                    name={'currency'}
                    required={true}
                  />

                  <FieldErrorMessage
                    errorExist={errorExist}
                    errorMessage={'Currency is Required'}
                    fieldValue={selectedOption?.currency?.value}
                  />
                </DropdownContainer>

                {selectedOption.currency.value === Currency.KES && (
                  <DropdownContainer>
                    <DropdownTitle title={'Select Bank'} />

                    <ReusableDropdown
                      handleOpenSelector={handleBankNamesDropdown}
                      dropdownPlaceholder={'Select Bank'}
                      selectedOption={selectedOption?.bankName?.name}
                      classes={'pr-5 pl-2'}
                      name={'bankName'}
                      required={true}
                    />

                    <FieldErrorMessage
                      errorExist={errorExist}
                      errorMessage={'Bank name is Required'}
                      fieldValue={selectedOption?.bankName?.name}
                    />
                  </DropdownContainer>
                )}

                {selectedOption.currency.value !== Currency.KES && (
                  <CustomInput
                    type="text"
                    title="Bank Name"
                    name="bankName"
                    formik={formik}
                    getInputClasses={getInputClasses}
                    required={selectedOption?.currency?.value !== Currency.KES}
                    errorMessage={bankNameErrorMessage}
                  />
                )}
              </div>

              {selectedOption?.bankName?.name === 'Others' ? (
                <div className="d-flex justify-content-between flex-wrap">
                  <div></div>
                  <CustomInput
                    type="text"
                    title="Bank Name"
                    name="bankName"
                    formik={formik}
                    getInputClasses={getInputClasses}
                    required={selectedOption?.bankName?.name === 'Others'}
                    errorMessage={bankNameErrorMessage}
                  />
                </div>
              ) : null}

              <div className="d-flex justify-content-between flex-wrap">
                <CustomInput
                  type="text"
                  title="Account Name"
                  name="accountName"
                  formik={formik}
                  getInputClasses={getInputClasses}
                  required={true}
                />

                <CustomInput
                  type="text"
                  title="Account Number"
                  name="accountNumber"
                  formik={formik}
                  getInputClasses={getInputClasses}
                  required={true}
                />
              </div>

              <div className="d-flex justify-content-between flex-wrap">
                <CustomInput
                  type="text"
                  title="Swift Code"
                  name="swiftCode"
                  formik={formik}
                  getInputClasses={getInputClasses}
                  required={true}
                />

                <CustomInput
                  type="text"
                  title="Bank Branch"
                  name="bankBranch"
                  formik={formik}
                  getInputClasses={getInputClasses}
                  required={true}
                />
              </div>

              <ButtonsContainer justifyContent="justify-content-between">
                <CancelButton
                  buttonText="Cancel"
                  handleOnClick={() => history.push('/withdrawal-details')}
                  twinButton
                />

                <ConfirmButton
                  buttonText={'Save'}
                  buttonType={'submit'}
                  handleOnClick={id ? updateDetail : handleSubmit}
                  loading={loading}
                  twinButton
                />
              </ButtonsContainer>
            </KycPageContentWrapper>
          </form>
        </>

        <OptionsModal
          open={openDropdown}
          handleClose={() => closeDropdown()}
          items={formatCurrency(currencies)}
          handleSelect={handleSelectOption}
          selectedItem={selectedOption?.currency}
          placeholder={'Select currency'}
          name={'currency'}
        />

        <BankModal
          open={openBankNames}
          handleClose={() => closeBankNames()}
          items={[...bankData, otherBank]}
          handleSelect={handleSelectOption}
          selectedItem={selectedOption?.bankName}
          placeholder={'Select bank'}
          name={'bankName'}
        />

        <CommonSuccessModal
          open={show}
          setOpen={setShow}
          content={content}
          moreClasses={'bg-white'}
          handleButtonClick={() => {
            setShow(false);
            history.push('/withdrawal-details');
          }}
        />

        <ErrorModal
          backgroundColor="bg-orange-100"
          open={openError}
          content={errorText}
          setOpen={setOpenError}
        />
      </div>
    </AnimatedPageContainer>
  );
};

export default WithdrawalBankDetails;
