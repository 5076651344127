import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchWatchList, removeFromWatchList } from '../_redux/watchListCrud';
import SingleWatchList from './SingleWatchList';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

import './index.scss';
import { handleAlert } from '../../../helpers/handleSuccessNotification';
import { ConfirmButton } from '../../Common/ReusableButtons';
import { trackEventMixpanel } from '../../../eventTracking/mixpanel';

function WatchList() {
  const [loading, setLoading] = useState(true);
  const [watchList, setWatchList] = useState([]);

  const getWatchList = async () => {
    setLoading(true);
    let res = await fetchWatchList();
    setWatchList(res.data.data);
    setLoading(false);
  };

  const deleteWatchlist = async id => {
    setLoading(true);
    const response = await removeFromWatchList(id);
    handleAlert(response?.data?.message);
    getWatchList();
    setLoading(false);
  };

  useEffect(() => {
    getWatchList();
    localStorage.removeItem('pathName');
  }, []);

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <>
      <div
        style={{ fontSize: '16px' }}
        className="d-flex justify-content-between internal-container font-weight-bold p-2 mb-3"
      >
        <p style={{ marginTop: '15px' }}>My Watchlist</p>

        {watchList.length ? (
          <Link
            to="/plans"
            onClick={() => {
              trackEventMixpanel('Watchlist_View_Funds_Click');
            }}
          >
            <ConfirmButton
              buttonText={'View Funds'}
              buttonWidth={'width-140'}
            />
          </Link>
        ) : null}
      </div>
      <div>
        {watchList.length === 0 && !loading ? (
          <>
            <div style={{ background: '#DEECF7', maxHeight: '70vh' }}>
              <div className="internal-container text-center p-5 mb-3">
                <p>You have not added any fund to your watch list.</p>
                <Link
                  to="/plans"
                  onClick={() => {
                    trackEventMixpanel('Watchlist_View_Funds_Click');
                  }}
                >
                  <ConfirmButton
                    buttonText={'View Funds'}
                    buttonWidth={'width-140'}
                  />
                </Link>
              </div>
            </div>
          </>
        ) : (
          <div className="goals-container py-3 watchlist-container">
            {watchList.map(list => (
              <SingleWatchList
                key={list.id}
                {...list}
                deleteWatchlist={deleteWatchlist}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default WatchList;
