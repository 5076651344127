import React from 'react';

const InputValidationErrorMessage = ({ message, extraClasses }) => {
  return (
    <div
      style={{ color: '#F05B7F' }}
      className={`mt-1 input-container text-12 ${extraClasses}`}
    >
      <span>{message}</span>
    </div>
  );
};

export default InputValidationErrorMessage;
