import { useEffect } from 'react';

/**
 * Custom hook to observe an element by ID and add a conditional click event listener.
 *
 * @param {string} elementId - The ID of the element to observe.
 * @param {Function} clickCallback - The callback function to run when the element is clicked.
 */
const useObserveAndDetectClick = (elementId, clickCallback) => {
  useEffect(() => {
    // Create a MutationObserver to watch for changes in the DOM
    const observer = new MutationObserver(mutationsList => {
      mutationsList.forEach(mutation => {
        if (mutation.type === 'childList') {
          const element = document.getElementById(elementId);
          if (element) {
            const handleClick = event => {
              if (element.classList.contains('zsiq-toggle')) {
                clickCallback(event);
              }
            };

            element.addEventListener('click', handleClick);

            // Disconnect the observer once the element is found
            observer.disconnect();

            return () => {
              element.removeEventListener('click', handleClick);
              observer.disconnect();
            };
          }
        }
      });
    });

    // Start observing the body for changes
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [elementId, clickCallback]);
};

export default useObserveAndDetectClick;
