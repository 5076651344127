import React from 'react';

export const DisplayErrorOrSuccessMessage = ({
  success,
  successMessage,
  errorMessage,
}) => {
  return (
    <div className="my-10 text-center text-14">
      {success && successMessage ? (
        <div style={{ color: '#67CCCA' }}>{successMessage}</div>
      ) : errorMessage ? (
        <div style={{ color: '#F05B7F' }}>{errorMessage}</div>
      ) : null}
    </div>
  );
};
