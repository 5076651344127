import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import parsePhoneNumber from 'libphonenumber-js';

import { actions as profileActions } from '../../../Profile/_redux/profileRedux';

import convertAmountToNumber from '../../../../helpers/convertAmountToNumber';
import { goalInvestmentWithMPESA } from '../../../Goal/_redux/goalActions';
import { fundInvestmentWithMPESA } from '../../../Plan/_redux/plansActions';
import { paySubscriptionFeesWithMpesa } from '../../_redux/investmentAction';
import PhoneNumber from './PhoneNumber';
import {
  Currency,
  InvestmentAction,
  customerIOEventType,
  paymentMethod,
  pocketActionsEnum,
} from '../../../../helpers/constants';
import { topupPocket } from '../../../Pocket/_redux/axiosRequest';
import { CommonSuccessModal } from '../../../Common/CommonSuccessModal';
import { pocketContents } from '../../../Pocket/copies';
import { moneyFormat } from '../../../../helpers/moneyFormat';
import { validatePhoneNumber } from '../../../../helpers/validatePhoneNumber';
import { eventTracking } from '../../../../eventTracking/httpRequest';

const InvestmentType = {
  TOP_UP: 'TOP_UP',
  INITIAL_INVESTMENT: 'INITIAL_INVESTMENT',
};

const PayWithMpesa = ({ setPayWithMpesa, amount }) => {
  const profile = useSelector(state => state.profile);
  const investor = profile?.investor;

  const history = useHistory();
  const dispatch = useDispatch();

  const [promoCode, setPromoCode] = useState();
  const [phoneNumber, setPhoneNumber] = useState(
    investor?.phoneNumber
      ? parsePhoneNumber(investor?.phoneNumber).nationalNumber
      : '',
  );
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(
    false,
  );

  const [openCongratulationsModal, setOpenCongratulationsModal] = useState(
    false,
  );

  const investmentAction = useSelector(
    state => state?.investment?.investmentAction,
  );

  const reduxPlanInvestmentDtls = useSelector(
    state => state?.plan?.planInvestmentDetails,
  );

  const reduxGoalInvestmentDtls = useSelector(
    state => state?.goal?.goalInvestmentDetails,
  );

  const reduxSubscriptionPaymentDtls = useSelector(
    state => state?.subscription?.subscriptionPaymentDetails,
  );

  const pocketDetails = useSelector(state => state.pocketDetails);

  const check = () => {
    const value = localStorage.getItem('termsAndConditionsAccepted');
    setTermsAndConditionsAccepted(value);
  };

  useEffect(() => {
    const promoCode = localStorage.getItem('promoCode');
    check();
    setPromoCode(promoCode);
  }, []);

  const {
    GOAL_INVESTMENT,
    GOAL_TOPUP,
    DIRECT_INVESTMENT,
    DIRECT_INVESTMENT_TOPUP,
    SUBSCRIPTION,
    POCKET_TOPUP,
  } = InvestmentAction;

  const paymentCompletedSuccessfully = () => {
    localStorage.removeItem('discountInfo');
    localStorage.removeItem('promoCode');
    investmentAction === SUBSCRIPTION || investmentAction === POCKET_TOPUP
      ? setOpenCongratulationsModal(true)
      : history.push('/congratulations');
  };

  const handleSubmit = async ({
    setLoading,
    setAlert,
    setAlertOn,
    setShowCountDown,
  }) => {
    const fullPhoneNumber = `+254${phoneNumber}`;
    setAlertOn(false);
    setAlert({
      alertMessage: null,
      alertMessageType: null,
    });

    const isValid = validatePhoneNumber(fullPhoneNumber, phoneNumber);

    try {
      await eventTracking(customerIOEventType.PAY_NOW);
    } catch (error) {}

    if (isValid) {
      setLoading(true);
      setShowCountDown(true);
      try {
        // SEND PUSH NOTIFICATION ON MOBILE TO APPROVE PAYMENT
        const { TOP_UP, INITIAL_INVESTMENT } = InvestmentType;

        const type = [GOAL_INVESTMENT, DIRECT_INVESTMENT].includes(
          investmentAction,
        )
          ? INITIAL_INVESTMENT
          : [GOAL_TOPUP, DIRECT_INVESTMENT_TOPUP].includes(investmentAction)
          ? TOP_UP
          : null;

        const pocketId = pocketDetails?.id;

        const pocketTopupPayload = {
          paymentOption: paymentMethod.MPESA,
          amount,
          currency: Currency.KES,
          promoCode,
          phoneNumber: fullPhoneNumber,
          pocketId: Number(pocketId),
        };

        switch (investmentAction) {
          case POCKET_TOPUP:
            await topupPocket(pocketTopupPayload);
            break;

          case SUBSCRIPTION:
            await paySubscriptionFeesWithMpesa(
              reduxSubscriptionPaymentDtls?.id,
              fullPhoneNumber,
              amount,
              promoCode,
            );

            dispatch(
              profileActions.profileAction({
                ...profile,
                investor: {
                  ...investor,
                  subscription: reduxSubscriptionPaymentDtls,
                },
              }),
            );
            break;

          case GOAL_INVESTMENT:
          case GOAL_TOPUP:
            await goalInvestmentWithMPESA(
              reduxGoalInvestmentDtls.goalId,
              fullPhoneNumber,
              convertAmountToNumber(reduxGoalInvestmentDtls?.amount),
              type,
              promoCode,
            );
            break;

          case DIRECT_INVESTMENT:
          case DIRECT_INVESTMENT_TOPUP:
            await fundInvestmentWithMPESA(
              reduxPlanInvestmentDtls.planId,
              fullPhoneNumber,
              convertAmountToNumber(reduxPlanInvestmentDtls?.amount),
              type,
              promoCode,
              { termsAndConditionsAccepted: termsAndConditionsAccepted },
            );
            break;

          default:
            alert('Invalid action');
            break;
        }

        setLoading(false);
        setShowCountDown(false);

        paymentCompletedSuccessfully();
      } catch (error) {
        setShowCountDown(false);
        setLoading(false);
        setAlertOn(true);
        setAlert({
          alertMessage:
            error.response?.data?.message || error.response?.data?.errorMessage,
          alertMessageType: 'error',
        });
      }
    } else {
      setAlertOn(true);
      setAlert({
        alertMessage: 'Please provide a valid phone number e.g. 7********',
        alertMessageType: 'error',
      });
    }
  };

  const modalContent = (
    <>
      {investmentAction === POCKET_TOPUP
        ? pocketContents({
            inputOne: {
              amount: moneyFormat(amount),
              currency: Currency.KES,
            },
          })?.[pocketActionsEnum.TOPUP]?.congratulationsModalContent
        : "You've successfully upgraded to your new plan."}
    </>
  );

  return (
    <>
      <PhoneNumber
        phoneNumber={phoneNumber}
        setPayWithMpesa={setPayWithMpesa}
        setPhoneNumber={setPhoneNumber}
        pushNotification={handleSubmit}
      />

      <CommonSuccessModal
        open={openCongratulationsModal}
        setOpen={() => setOpenCongratulationsModal(false)}
        content={modalContent}
        moreClasses={'bg-white'}
        handleButtonClick={() => {
          history.push(
            investmentAction === POCKET_TOPUP
              ? `/pocket/${pocketDetails?.id}`
              : '/subscription',
          );
        }}
      />
    </>
  );
};

export default PayWithMpesa;
