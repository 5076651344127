import React, { useState, useEffect } from 'react';
import { countries } from 'countries-list';
import parsePhoneNumber from 'libphonenumber-js';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import Back from '../../Common/BackArrow';
import ReusableDropdown from '../../Common/ReusableDropdown';
import { serviceProviderOptions } from '../../../helpers/constants';
import OptionsModal from '../../Common/OptionsModal';
import { FieldErrorMessage } from '../../Individual/components/FieldErrorMessage';
import { checkForErrors } from '../../../helpers/checkForErrors';
import SelectPhoneNumber from '../../Common/SelectPhoneNumber';
import { ValidationErrors } from '../../Business/components/ValidationErrors';
import { KycPageContentWrapper } from '../../Common/KycPageContentWrapper/KycPageContentWrapper';
import {
  createSettlementDetails,
  fetchSingleDetail,
  updateSettlementDetails,
} from '../_redux/withdrawalCrud';
import { CommonSuccessModal } from '../../Common/CommonSuccessModal';
import ErrorModal from '../../Common/ErrorModal/index';
import {
  getFullPhoneNumber,
  validatePhoneNumber,
} from '../../../helpers/validatePhoneNumber';
import { DropdownContainer } from '../../Common/ReusableDropdown/DropDownContainer';
import { DropdownTitle } from '../../Common/Dropdown/DropdownTitle';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import { AnimatedPageContainer } from '../../Common/AnimatedPageContainer';

const WithdrawalDetailsMobileMoney = props => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [detailsData, setDetailsData] = useState(null);

  const [validPhoneNumber, setValidPhoneNumber] = useState(true);

  const [selectedCountry, setSelectedCountry] = useState(
    detailsData?.phoneNumber
      ? {
          countryCallingCode: parsePhoneNumber(`${detailsData?.phoneNumber}`)
            .countryCallingCode,
          name:
            countries[parsePhoneNumber(`${detailsData?.phoneNumber}`).country]
              ?.name,
          flag: `flag-icon-${parsePhoneNumber(
            `${detailsData?.phoneNumber}`,
          ).country.toLowerCase()}`,
        }
      : {
          countryCallingCode: '254',
          name: 'Kenya',
          flag: `flag-icon-ke`,
        },
  );

  const [show, setShow] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const handleSelect = country => {
    setSelectedCountry(country);
  };

  const [openDropdown, setOpenDropdown] = useState(false);
  const [errorExist, setErrorExist] = useState();
  const [selectedOption, setSelectedOption] = useState({
    serviceProvider: { label: 'Safaricom M-Pesa', value: 'MPESA' },
  });

  const closeDropdown = () => {
    setOpenDropdown(false);
  };

  const handleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleSelectOption = (item, name) => {
    setSelectedOption({ ...selectedOption, [name]: item });
    closeDropdown();
  };

  const initialValues = {
    phoneNumber: detailsData?.phoneNumber
      ? detailsData?.phoneNumber.slice(4)
      : '',
  };

  const ValidationSchema = Yup.object().shape({
    phoneNumber: Yup.string().required('Required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: ValidationSchema,
  });

  const fields = {
    serviceProvider: selectedOption?.serviceProvider.value,
  };

  const fetchDetail = async () => {
    try {
      const res = await fetchSingleDetail(id);
      if (res.status === 200) {
        setDetailsData(res?.data);
      }
    } catch (error) {}
  };

  const handleSubmit = async () => {
    const fullPhoneNumber = getFullPhoneNumber(
      selectedCountry?.countryCallingCode,
      formik.values.phoneNumber,
    );
    const isValid = validatePhoneNumber(
      fullPhoneNumber,
      formik.values.phoneNumber,
    );
    setValidPhoneNumber(isValid);

    const noErrors = checkForErrors({ ...formik?.values, ...fields });
    if (!noErrors) {
      setErrorExist(true);
      return;
    }

    if (!isValid) {
      return;
    }

    try {
      setLoading(true);
      const res = await createSettlementDetails({
        type: 'MOBILE_MONEY',
        serviceProvider: selectedOption.serviceProvider.value,
        currency: 'KES',
        phoneNumber: fullPhoneNumber,
      });

      if (res && res.status === 201) {
        setLoading(false);
        setShow(true);
      }
    } catch (error) {
      setLoading(false);
      setShow(false);
      setOpenError(true);
      setErrorText(error.response.data.message || error.message);
    }
  };

  const updateData = async e => {
    e.preventDefault();
    const fullPhoneNumber = getFullPhoneNumber(
      selectedCountry?.countryCallingCode,
      formik.values.phoneNumber,
    );

    const isValid = validatePhoneNumber(
      fullPhoneNumber,
      formik.values.phoneNumber,
    );
    setValidPhoneNumber(isValid);

    const noErrors = checkForErrors({ ...formik?.values, ...fields });
    if (!noErrors) {
      setErrorExist(true);
      return;
    }

    if (!isValid) {
      return;
    }
    try {
      setLoading(true);
      const res = await updateSettlementDetails(
        {
          serviceProvider: selectedOption.serviceProvider.value,
          currency: 'KES',
          phoneNumber: fullPhoneNumber,
        },
        id,
      );
      if (res && res.status === 200) {
        setLoading(false);
        setShow(true);
      }
    } catch (error) {
      setLoading(false);
      setShow(false);
      setOpenError(true);
      setErrorText(error.response.data.message || error.message);
    }
  };

  const content = (
    <p>
      {id
        ? 'Your details has been updated successfully.'
        : 'You MPESA details have been submitted successfully.'}
    </p>
  );

  useEffect(() => {
    id && fetchDetail();
  }, [id]);

  return (
    <AnimatedPageContainer>
      <div className="mt-5">
        <Back title="Mobile Money Details" />
        <>
          <form
            className="px-md-20 px-3"
            onSubmit={id ? formik.updateData : formik.handleSubmit}
          >
            <KycPageContentWrapper>
              <div className="d-flex justify-content-between flex-wrap">
                <DropdownContainer>
                  <DropdownTitle title={'Service Provider'} />

                  <ReusableDropdown
                    handleOpenSelector={handleDropdown}
                    dropdownPlaceholder={''}
                    selectedOption={selectedOption?.serviceProvider?.label}
                    classes={'pr-5 pl-2'}
                    name={'Service Provider'}
                    required={true}
                  />
                  <FieldErrorMessage
                    errorExist={errorExist}
                    errorMessage={'Service Provider is Required'}
                    fieldValue={selectedOption.serviceProvider.value}
                  />
                </DropdownContainer>

                <DropdownContainer>
                  <DropdownTitle title={'Mobile Number'} />

                  <div className="d-flex w-100">
                    <div className="width-40">
                      <SelectPhoneNumber
                        selectedCountry={selectedCountry}
                        disableInput={true}
                      />
                    </div>
                    <div className="ml-2 width-60">
                      <input
                        name="phoneNumber"
                        className={`form-control border-gray-450 border-radius-8 height-45 input-field`}
                        type="tel"
                        {...formik.getFieldProps('phoneNumber')}
                        autoComplete="off"
                      />

                      {formik.touched.phoneNumber &&
                      formik.errors.phoneNumber ? (
                        <ValidationErrors
                          errorMessage={formik.errors.phoneNumber}
                        />
                      ) : !validPhoneNumber ? (
                        <ValidationErrors
                          errorMessage={
                            'Enter a valid phone number e.g. 7********'
                          }
                        />
                      ) : null}
                    </div>
                  </div>
                </DropdownContainer>
              </div>

              <ButtonsContainer justifyContent="justify-content-between">
                <CancelButton
                  buttonText="Cancel"
                  handleOnClick={() => history.push('/withdrawal-details')}
                  twinButton
                />

                <ConfirmButton
                  buttonText={'Save'}
                  buttonType={'submit'}
                  handleOnClick={id ? updateData : handleSubmit}
                  loading={loading}
                  twinButton
                />
              </ButtonsContainer>
            </KycPageContentWrapper>
          </form>
        </>
        <OptionsModal
          open={openDropdown}
          handleClose={() => closeDropdown()}
          items={serviceProviderOptions}
          handleSelect={handleSelectOption}
          selectedItem={selectedOption?.currency}
          placeholder={'Select Provider'}
          name={'serviceProvider'}
        />

        <CommonSuccessModal
          open={show}
          setOpen={setShow}
          content={content}
          moreClasses={'bg-white'}
          handleButtonClick={() => {
            setShow(false);
            history.push('/withdrawal-details');
          }}
        />
        <ErrorModal
          backgroundColor="bg-orange-100"
          open={openError}
          content={errorText}
          setOpen={setOpenError}
        />
      </div>
    </AnimatedPageContainer>
  );
};

export default WithdrawalDetailsMobileMoney;
