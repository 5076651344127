import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import Input from '../../Common/Input/ControlledInput';
import Back from '../../Common/BackArrow';
import {
  InvestmentAction,
  allowWhiteSpace,
  allowWhiteSpaceError,
  investmentType,
} from '../../../helpers/constants';
import { actions as investmentReducerActions } from '../../Invest/_redux/investmentReducers';
import { actions as goalReducerActions } from '../_redux/goalReducers';
import { ConfirmButton } from '../../Common/ReusableButtons';
import MainWrapper from '../../Pocket/components/MainWrapper';
import { AnimatedPageContainer } from '../../Common/AnimatedPageContainer';

const NewGoal = props => {
  const [goalPayload, setGoalPayload] = useState();
  const reduxGoalInvestmentDtls = useSelector(
    state => state?.goal?.goalInvestmentDetails,
  );

  const createdGoalsList = useSelector(state => state.createdGoalsList);

  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    setGoalPayload(reduxGoalInvestmentDtls);
  }, []);

  const { intl } = props;
  let history = useHistory();
  const dispatch = useDispatch();

  const GoalNameSchema = Yup.object().shape({
    goalName: Yup.string()
      .matches(allowWhiteSpace, allowWhiteSpaceError)
      .min(3, 'Minimum 3 Characters')
      .max(50, 'Maximum 50 Characters')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const handleChange = event => {
    setErrorMessage(null);
    const { name, value } = event.target;
    const goalNameExist =
      createdGoalsList?.length > 0 &&
      createdGoalsList?.find(
        goal => goal?.name.toLowerCase() === value?.trim()?.toLowerCase(),
      );

    if (goalNameExist) {
      setErrorMessage(`You've already invested in this goal. Please top up`);
    }

    setGoalPayload({
      ...goalPayload,
      [name]: value,
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { goalName: goalPayload?.goalName },
    validationSchema: GoalNameSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (values !== '') {
        setSubmitting(true);
        localStorage.removeItem('subscriptionId');
        localStorage.removeItem('promoCode');
        localStorage.removeItem('goalDiscountInfo');

        dispatch(
          investmentReducerActions.updateInvestmentAction(
            InvestmentAction.GOAL_INVESTMENT,
          ),
        );

        dispatch(
          goalReducerActions.updateGoalInvestmentDetails({
            ...reduxGoalInvestmentDtls,
            investmentType: investmentType.INITIAL_INVESTMENT,
            goalName: values.goalName,
          }),
        );

        localStorage.setItem('previousPage', history.location.pathname);
        history.push('/goal/calculator');
      } else {
        setSubmitting(false);
      }
    },
  });
  return (
    <AnimatedPageContainer>
      <Back
        title={'Name your goal'}
        onClick={() => history.push('/goal/select-goal')}
      />
      <MainWrapper>
        <p className="text-center mt-5" style={{ fontSize: '20px' }}>
          Give your financial goal a name…
        </p>
        <form className="mt-5" id="kt_form" onSubmit={formik.handleSubmit}>
          {/* <!--begin: Wizard Step 1--> */}

          {/* <!--begin::Form Group--> */}
          <div className="form-group fv-plugins-icon-container">
            <div className="mb-2 form-group">
              <div className="input-container mx-auto">
                <Input
                  placeholder="Goal Name(E.g. car, new house)"
                  type="text"
                  className={`form-control input-field border-gray-450 ${getInputClasses(
                    'goalName',
                  )}`}
                  name="goalName"
                  formik={formik}
                  defaultValue={goalPayload?.goalName || ''}
                  handleChange={handleChange}
                />
              </div>
            </div>
            {(formik.touched.goalName && formik.errors.goalName) ||
            errorMessage ? (
              <div className="input-container mx-auto fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.goalName
                    ? formik.errors.goalName
                    : errorMessage}
                </div>
              </div>
            ) : null}
          </div>
          {/* <!--end::Form Group--> */}

          <div className="mb-10 text-center mt-10 mb-lg-5">
            <ConfirmButton
              buttonType={'submit'}
              buttonText="Continue"
              disable={errorMessage?.length > 0}
            />
          </div>
        </form>
        {/* <!--end::Form--> */}
      </MainWrapper>
    </AnimatedPageContainer>
  );
};

export default injectIntl(NewGoal);
