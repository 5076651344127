import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const DELETE_INVESTOR = `${REACT_APP_BASE_URL}/v1/investor/investors/request-account-deactivation`;
const deleteInvestor = async id => {
  const res = await axios.post(`${DELETE_INVESTOR}`);
  return res;
};

export default deleteInvestor;
