import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import investBtnIcon from '../../../../icons/investBtnIcon.svg';
import MessageAlert from '../../../Common/MessageAlert';
import { CancelButton, ConfirmButton } from '../../../Common/ReusableButtons';
import { PlanDetailsCard } from '../PlanDetailsCard';

const AddToWatchlist = ({
  deleteWatchlist,
  addToTheWatchList,
  withdrawalError,
  planDetails,
  generateButtonText,
  handleInvest,
  disableInvestButton,
  termsAndConditionsRequired,
  openTermsAndConditionModal,
}) => {
  const [showWithdrawalError, setShowWithdrawalError] = useState(false);
  const originPartner = useSelector(state => state?.profile?.originPartner);

  const {
    isWatchList,
    name: planName,
    fundManager,
    isFractionalStock,
    isInvested,
  } = planDetails;

  return (
    <PlanDetailsCard title={isFractionalStock ? 'Company' : 'Fund manager'}>
      <div className="p-5 d-flex align-items-center responsive-container flex-wrap justify-content-between">
        <div className="width">
          <div className="d-flex align-items-center mb-2">
            <div
              style={{
                width: '150px',
                padding: '5px',
                border: '1px solid #c4c4c4',
                borderRadius: '5px',
              }}
            >
              <img
                style={{ width: '100%', height: '100%' }}
                src={fundManager?.logo}
                alt="Fund Manager Logo"
              />
            </div>
            <div className="ml-3 d-flex flex-column">
              <span className="text-16 text-blue font-weight-600">
                {planName}
              </span>
              <small className="text-11">
                {fundManager?.longName
                  ? fundManager?.longName
                  : fundManager?.fundname}
              </small>
            </div>
          </div>
        </div>
        <div className="width margin-left-50">
          <ConfirmButton
            buttonText={generateButtonText()}
            handleOnClick={() =>
              termsAndConditionsRequired && !isInvested
                ? openTermsAndConditionModal()
                : handleInvest('Button_2')
            }
            disable={disableInvestButton}
            buttonIcon={investBtnIcon}
            buttonWidth={'button-w-360 button-md-w-360'}
            classes={'ml-auto d-flex justify-content-center align-items-center'}
          />

          {!originPartner && (
            <CancelButton
              handleOnClick={
                isWatchList
                  ? () => deleteWatchlist(isWatchList?.id)
                  : addToTheWatchList
              }
              buttonWidth={'button-w-360 button-md-w-360'}
              buttonText={
                isWatchList ? 'Remove From Watchlist' : 'Add To Watchlist'
              }
              classes={
                'mt-5 ml-auto d-flex justify-content-center align-items-center'
              }
              buttonIcon="/media/svg/ndovu/add-to-watch-list-button-icon.svg"
            />
          )}
        </div>
      </div>
      {showWithdrawalError && (
        <div className="pl-5 pr-5">
          <MessageAlert
            closeAlert={() => setShowWithdrawalError(false)}
            alert={withdrawalError}
          />
        </div>
      )}
    </PlanDetailsCard>
  );
};

export default AddToWatchlist;
