import React from 'react';
import { motion } from 'framer-motion/dist/framer-motion';

export const NdovuLogo = ({ image }) => {
  return (
    <motion.div
      animate={{ rotateY: 360, scale: [0, 1, 0.5, 1] }}
      transition={{
        duration: 2.5,
        delay: 0.5,
      }}
    >
      <img src={image} alt="Elephant" />
    </motion.div>
  );
};
